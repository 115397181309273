import { apiFactory } from 'api';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import reducers from './root';

export const configureStore = () => {
  const api = apiFactory();


  let middlewares;
  if (process.env.REACT_APP_FRONTEND_VERSION === 'DEV') {
    middlewares = composeWithDevTools(
      applyMiddleware(thunk.withExtraArgument({ api }))
    );
  } else middlewares = applyMiddleware(thunk.withExtraArgument({ api }));

  const store = createStore(reducers, middlewares);
  return store;
};
