/* Gogo Language Texts

Table of Contents

01.General
02.User Login, Logout, Register
03.Menu
04.Error Page
*/

module.exports = {
  /* 01.General */
  'general.copyright': 'Gogo React © 2018 All Rights Reserved.',

  /* 02.User Login, Logout, Register */
  'user.login-title': 'Login',
  'user.register': 'Register',
  'user.forgot-password': 'Parolă uitată',
  'user.reset-password': 'Resetare Parolă',
  'user.new-password': 'Parolă nouă',
  'user.new-password-again': 'Confirmă parola',
  'user.email': 'E-mail',
  'user.password': 'Password',
  'user.forgot-password-question': 'Ai uitat parola?',
  'user.fullname': 'Full Name',
  'user.login-button': 'LOGIN',
  'user.register-button': 'REGISTER',
  'user.reset-password-button': 'Resetează parola',
  'user.buy': 'BUY',
  'user.username': 'Username',

  /* 03.Menu */
  'menu.app': 'Home',
  'menu.dashboards': 'Dashboards',
  'menu.dashboard': 'Dashboard',
  'menu.home': 'Informații',
  'menu.about-us': 'Despre Noi',
  'menu.presentation': 'Prezentare Conținut',
  'menu.usage': 'Cum funcționează?',
  'menu.packages': 'Pachete disponibile',
  'menu.feedback': 'Feedback',
  'menu.map': 'Hartă',
  'menu.harta': 'Hartă',
  'menu.market': 'Piață',
  'menu.communities': 'Comunități',

  'menu.map-long': 'Harta producătorilor locali',
  'menu.start': 'Start',
  'menu.producers': 'Producători',
  'menu.products': 'Produse',
  'menu.partners': 'Parteneri',

  'menu.cont': 'Cont',
  'menu.fito': 'Fitosanitare',
  'menu.info': 'Detalii Profil',
  'menu.date-personale': 'Detalii Profil',
  'menu.my-products': 'Produsele Mele',
  'menu.produsele-mele': 'Produsele Mele',
  'menu.my-demands': 'Cererile Mele',
  'menu.cererile-mele': 'Cererile Mele',
  'menu.my-offers': 'Ofertele Mele',
  'menu.the-market': 'Piața Micul Aprozar',
  'menu.adrese-ridicare': 'Adrese ridicare',
  'menu.invite-users': 'Invită un prieten',
  'menu.my-orders': 'Comenzi Efectuate',
  'menu.comenzi-efectuate': 'Comenzi Efectuate',
  'menu.my-orders-received': 'Comenzi Primite',
  'menu.comenzi-primite': 'Comenzi Primite',
  'menu.add-product': 'Adaugă produs',
  'menu.adauga-produs': 'Adaugă produs',
  'menu.add-demand': 'Cerere Personalizată',
  'menu.adauga-cererea': 'Adaugă Cerere',
  'menu.add-offer': 'Adaugă ofertă',
  'menu.ui': 'UI',
  'menu.charts': 'Charts',
  'menu.chat': 'Chat comenzi',
  'menu.demands-chat': 'Chat piață',
  'menu.notifications': 'Notificări',
  'menu.survey': 'Survey',
  'menu.todo': 'Todo',
  'menu.search': 'Search',
  'menu.docs': 'Docs',
  'menu.blank-page': 'Blank Page',
  'menu.cart': 'Coșul Meu',
  'menu.for-producers': 'Pentru Producători',
  'menu.for-consumers': 'Pentru Consumatori',
  'menu.t&c': 'Termeni și condiții',

  /* 04.Error Page */
  'pages.error-title': 'Această pagină a fost mutată sau nu mai există!',
  'pages.error-code': 'Eroarea',
  'pages.go-back-home': 'ÎNAPOI',
  'pages.add-to-cart-modal-title': 'Adaugă produsul în coș',
  'pages.product-name': 'Produs',
  'pages.category': 'Categorie',
  'pages.description': 'Descriere',
  'pages.price': 'Preț/UM',
  'pages.quantity': 'Cantitate',
  'pages.cancel': 'Renunță',
  'pages.submit': 'Adaugă în coș',
  'pages.total': 'Total',
  'pages.add-product': 'Adaugă produs',
  'pages.add-offer': 'Adaugă ofertă',
  'pages.add-address': 'Adaugă adresă',
  'pages.orderby': 'Ordonează după: ',
  'pages.display-options': 'Opțiuni',

  'pages.istoric-plati': 'Istoric plați',
  'menu.istoric-plati': 'Istoric plați',

  /* Detalii Produs */
  'details.description': 'Descriere',
  'details.details': 'Detalii',
  'details.reviews': 'Recenzii producător',
  'details.suggestions': 'Produse similare',

  /* Register */
  'register.consumer': 'Poți cumpăra',
  'register.producer': 'Poți cumpăra și vinde',

  /* Communities */
  'communities.general': 'Comunități locale',
  'communities.resita': 'Piețe Reșița',

  //Information
  'menu.informations': 'Cum funcționează',
  'menu.producer-info': 'Pentru producatori',
  'menu.consumer-info': 'Pentru consumatori',
  'menu.general': 'Informatii generale',

  // Comenzi

  'menu.cos': 'Coșul meu',
  'menu.finalizare-comanda': 'Finalizare comandă',
};
