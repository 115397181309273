import { account } from './account';
import { map } from './map';
import { meta } from './meta';
import { infoPages } from './infoPages';
import { stripe } from './stripe';

export const apiFactory = () => ({
  data: {
    map: () => map(),
    account: () => account(),
    meta: () => meta(),
    info_pages: () => infoPages(),
    stripe: () => stripe(),
  },
});

export type ApiFactory = ReturnType<typeof apiFactory>;
