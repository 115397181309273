import { ActionCreator } from 'redux';
import {
  partnersData,
  getPartners,
  getTermsAndConditions,
  termsConditionsData,
  getPages,
  Pages,
  TermsAndConditions,
} from './types';

import { Thunk } from 'store/types';
import { v4 as uuidv4 } from 'uuid';
import { beginActivity, endActivity, setError } from 'store/request';

import { ActionTypes } from './types';

export const getPartnersAction: ActionCreator<getPartners> = (
  data: partnersData
) => ({
  type: ActionTypes.GET_PARTNERS,
  payload: {
    data,
  },
});

export const getTermsAndConditionsAction: ActionCreator<getTermsAndConditions> = (
  data: TermsAndConditions
) => ({
  type: ActionTypes.GET_TERMS_CONDITIONS,
  payload: {
    data,
  },
});

export const getPagesAction: ActionCreator<getPages> = (data: Pages) => ({
  type: ActionTypes.GET_PAGES,
  payload: {
    data,
  },
});

export const getAllPartners = (): Thunk => async (
  dispatch,
  getState,
  context
) => {
  const activityId = uuidv4();
  try {
    await dispatch(
      beginActivity({
        type: ActionTypes.GET_PARTNERS,
        uuid: activityId,
      })
    );
    const partners = await context.api.data.info_pages().getPartners();

    dispatch(getPartnersAction(partners));
  } catch (error) {
    await dispatch(
      setError({
        type: ActionTypes.GET_PARTNERS,
        error: error.message,
        uuid: uuidv4(),
      })
    );
  } finally {
    await dispatch(endActivity({ uuid: activityId }));
  }
};

export const getTheTermsAndConditions = (): Thunk => async (
  dispatch,
  getState,
  context
) => {
  const activityId = uuidv4();
  try {
    await dispatch(
      beginActivity({
        type: ActionTypes.GET_TERMS_CONDITIONS,
        uuid: activityId,
      })
    );
    const termsAndConditions = await context.api.data
      .info_pages()
      .getTermsAndConditions();

    dispatch(getTermsAndConditionsAction(termsAndConditions));
  } catch (error) {
    await dispatch(
      setError({
        type: ActionTypes.GET_TERMS_CONDITIONS,
        error: error.message,
        uuid: uuidv4(),
      })
    );
  } finally {
    await dispatch(endActivity({ uuid: activityId }));
  }
};

export const getThePages = (): Thunk => async (dispatch, getState, context) => {
  const activityId = uuidv4();
  try {
    await dispatch(
      beginActivity({
        type: ActionTypes.GET_PAGES,
        uuid: activityId,
      })
    );
    const pages = await context.api.data.info_pages().getAllPages();

    dispatch(getPagesAction(pages));
  } catch (error) {
    await dispatch(
      setError({
        type: ActionTypes.GET_PAGES,
        error: error.message,
        uuid: uuidv4(),
      })
    );
  } finally {
    await dispatch(endActivity({ uuid: activityId }));
  }
};
