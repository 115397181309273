import { sendGetRequest } from 'api/utils';
import { api_url_strapi } from '../utils/consts';
import { GetMetaResponse, MetaData } from './types';

export default () => ({
  getMeta: async (): Promise<MetaData> => {
    const { data }: GetMetaResponse = await sendGetRequest(
      api_url_strapi + '/configs'
    );
    return data;
  },
});
