import _ from 'lodash';
import { createSelector } from 'reselect';
import { State } from './types';

export const selectIsLogged = createSelector(
  (state: State) => {
    const { user } = state;
    if (_.isEmpty(user.accessToken)) {
      const localToken = localStorage.getItem('token');
      return _.isEmpty(localToken) ? false : true;
    } else {
      return true;
    }
  },
  (logged) => logged
);

export const selectIsFetched = createSelector(
  (state: State) => {
    const { user } = state;

    return _.isEmpty(user.firstName) || !user.id ? false : true;
  },
  (fetched) => fetched
);

export const selectUserInformation = createSelector(
  (state: State) => {
    const { user, products, demands } = state;
    return { user, products, demands };
  },
  (userInformation) => userInformation
);

export const selectCart = createSelector(
  (state: State) => {
    const { cart: cart2 } = state;
    const {
      user: { id: userId },
    } = state;
    const cart = cart2.filter((products) => products.userId !== userId);
    return { cart };
  },
  (cart) => cart
);

export const selectDemands = createSelector(
  (state: State) => {
    const { demands } = state;
    return { demands };
  },
  (demands) => demands
);

export const selectCartIsFetched = createSelector(
  (state: State) => {
    const { fetchedCart } = state;
    return fetchedCart;
  },
  (fetched) => fetched
);

export const selectDemandsIsFetched = createSelector(
  (state: State) => {
    const { fetchedDemands } = state;
    return fetchedDemands;
  },
  (fetched) => fetched
);

export const selectIsSuspended = createSelector(
  (state: State) => {
    const {
      user: { isSuspended },
    } = state;
    return isSuspended;
  },
  (suspended) => suspended
);
