import { MapPosition } from 'api/map/types';
import Geocode from 'react-geocode';
Geocode.setApiKey(process.env.REACT_APP_MAP_API_KEY);

/**
 * @param {string} keyName - A key to identify the value.
 * @param {any} keyValue - A value associated with the key.
 * @param {number} ttl- Time to live in seconds.
 */
export const setTTLLocalStorage = (keyName, keyValue, ttl) => {
  const data = {
    value: keyValue, // store the value within this object
    ttl: Date.now() + ttl * 1000, // store the TTL (time to live)
  };

  // store data in LocalStorage
  localStorage.setItem(keyName, JSON.stringify(data));
};

/**
 * @param {string} keyName - A key to identify the data.
 * @returns {any|null} returns the value associated with the key if its exists and is not expired. Returns `null` otherwise
 */
export const getTTLLocalStorage = (keyName) => {
  const data = localStorage.getItem(keyName);
  if (!data) {
    // if no value exists associated with the key, return null
    return null;
  }

  const item = JSON.parse(data);

  // If TTL has expired, remove the item from localStorage and return null
  if (Date.now() > item.ttl) {
    localStorage.removeItem(keyName);
    return null;
  }

  // return data if not expired
  return item.value;
};

export const getLocation = async () => {
  return new Promise(async function (
    resolve: (value: BrowserLocation) => void,
    reject
  ) {
    const position = {
      latitude: 45.8266751301338,
      longitude: 25.13688627812501,
    };

    let address = '';

    var options = { enableHighAccuracy: true, maximumAge: 100, timeout: 60000 };
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (newPosition) => {
          address = await getAddress(
            newPosition.coords.latitude,
            newPosition.coords.longitude
          );
          position.latitude = newPosition.coords.latitude;
          position.longitude = newPosition.coords.longitude;
          return resolve({ position: position, address: address });
        },
        async (error) => {
          address = await getAddress(position.latitude, position.longitude);
          return resolve({ position: position, address: address });
        },
        options
      );
    } else {
      address = await getAddress(position.latitude, position.longitude);
      return resolve({ position: position, address: address });
    }
  });
};

export const getGeolocationInformation = async (newPosition?: MapPosition) => {
  Geocode.setApiKey(process.env.REACT_APP_MAP_API_KEY);

  let positionLocal = window.localStorage.getItem('position');

  if (!positionLocal) {
    // GET LOCATION
    const newLocation = await getLocation();
    if (newLocation) {
      window.localStorage.setItem(
        'position',
        JSON.stringify({
          lat: newLocation.position.latitude,
          lng: newLocation.position.longitude,
          stringAddress: newLocation.address,
          km: 100000,
        })
      );
    }
    positionLocal = JSON.stringify({
      lat: newLocation.position.latitude,
      lng: newLocation.position.longitude,
      stringAddress: newLocation.address,
      km: 100000,
    });
  }

  const positionStore: {
    lat: number;
    lng: number;
    stringAddress: string;
    km: number;
  } = JSON.parse(positionLocal);

  if (!newPosition) return positionStore;

  if (
    newPosition &&
    ((positionStore.lat !== newPosition.latitude &&
      positionStore.lng !== newPosition.longitude) ||
      positionStore.km !== newPosition.km)
  ) {
    positionStore.lat = newPosition.latitude;
    positionStore.lng = newPosition.longitude;
    positionStore.stringAddress = await getAddress(
      positionStore.lat,
      positionStore.lng
    );
    positionStore.km = newPosition.km;
    window.localStorage.setItem(
      'position',
      JSON.stringify({
        lat: positionStore.lat,
        lng: positionStore.lng,
        stringAddress: positionStore.stringAddress,
        km: positionStore.km,
      })
    );
  }

  return positionStore;
};

export interface BrowserLocation {
  position: {
    latitude: number;
    longitude: number;
  };
  address: string;
}

export const getAddress = async (lat, lng) => {
  let address = '';
  await Geocode.fromLatLng(lat, lng).then(
    (response) => {
      let locality = '';
      let route = '';
      let street_number = '';
      let political = '';
      response.results[0].address_components.forEach((element) => {
        if (element.types.includes('locality') && !locality) {
          locality += element.long_name + ', ';
        }
        if (element.types.includes('route') && !route) {
          route += element.long_name + ', ';
        }
        if (element.types.includes('street_number') && !street_number) {
          street_number += element.long_name + ', ';
        }
        if (element.types.includes('political') && !political) {
          political += element.long_name + ', ';
        }
        if (locality && route && street_number) {
          address = locality + route + street_number;
        } else if (locality && political && !street_number && route) {
          if (locality !== political) {
            address = locality + political;
          } else {
            address = locality;
          }
        } else if (locality && political && !street_number && !route) {
          if (locality !== political) {
            address = locality + political;
          } else {
            address = locality;
          }
        } else if (!locality) {
          address = political;
        } else {
          address = locality + route + street_number + political;
        }
        var last2 = address.slice(-2);
        if (last2 === ', ') {
          address = address.substr(0, address.length - 2);
        }
      });
    },
    (error) => {
      console.error(error);
    }
  );
  return address;
};
