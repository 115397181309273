import {
  sendGetRequest,
  sendPostRequest,
  sendPutRequest,
  sendDeleteRequest,
} from 'api/utils';
import { format } from 'date-fns';
import { api_url } from '../utils/consts';

export default () => ({
  getSubscriptionEnd: async (token: any): Promise<any> => {
    const { data }: any = await sendGetRequest(
      api_url + 'api/v1.0/platform/payment/subscriptionEnd',
      token
    );
    return data;
  },
  openCheckoutSession: async (token: any): Promise<any> => {
    const { data } = await sendPostRequest(
      api_url + 'api/v1.0/platform/payment/checkoutSession',
      {},
      token
    );
    return data;
  },
  openCustomerPortal: async (token: any): Promise<any> => {
    const { data } = await sendPostRequest(
      api_url + 'api/v1.0/platform/payment/customerPortal',
      {},
      token
    );
    return data;
  },
  getPayments: async (token: any): Promise<any> => {
    const { data } = await sendGetRequest(
      api_url + 'api/v1.0/platform/payment/payments',
      token
    );
    return data;
  },
  // getpaymenthsAndSubscriptionDates: async (token: any): Promise<any> => {
  //   const { data }: any = await sendGetRequest(
  //     api_url + 'api/v1.0/platform/payment/paymenthsAndSubscriptionDates',
  //     token
  //   );
  //   return data;
  // },
  // getSetupIntent: async (token: any): Promise<any> => {
  //   const { data }: any = await sendGetRequest(
  //     api_url + 'api/v1.0/platform/payment/setupIntent',
  //     token
  //   );
  //   return data;
  // },
  getStripePrices: async (): Promise<any> => {
    const {
      data: { data },
    } = await sendGetRequest(
      api_url + 'api/v1.0/platform/payment/stripePrices'
    );
    return data;
  },
  // deleteDefaultPaymentMethod: async (token: any): Promise<any> => {
  //   const { data } = await sendDeleteRequest(
  //     api_url + 'api/v1.0/platform/payment/paymentMethod',
  //     token
  //   );
  //   return data;
  // },
   getCustomerInvoices: async (token: any): Promise<any> => {
     let { data } = await sendGetRequest(
       api_url + 'api/v1.0/platform/payment/customerInvoices',
       token
     );
     if (data.data) {
       data = data.data;
     }
     const Invoicespdf = data.map((invoice) => {
       return {
         pdf: `${process.env.REACT_APP_GOOGLE_CLOUD_URL}/${invoice.id}.pdf`,
         paidDate:
           invoice.status_transitions.paid_at === null
             ? 'Neplătit'
             : format(
                 new Date(invoice.status_transitions.paid_at * 1000),
                 'dd.MM.yyyy'
               ),
         amount_paid: invoice.amount_paid,
         amount_due: invoice.amount_due,
         amount_remaining: invoice.amount_remaining,
         invoiceId: invoice.id,
         status: invoice.status,
       };
     });
     return Invoicespdf;
   },
  // createStripeSubscription: async (
  //   priceId: string,
  //   token: any
  // ): Promise<any> => {
  //   const {
  //     data: { clientSecret, subscriptionId },
  //   } = await sendPostRequest(
  //     api_url + 'api/v1.0/platform/payment/subscription',
  //     { priceId },
  //     token
  //   );
  //   return { clientSecret: clientSecret, subscriptionId: subscriptionId };
  // },

  // getDefaultCardInfo: async (token: any): Promise<any> => {
  //   const {
  //     data: { brand, last4 },
  //   }: any = await sendGetRequest(
  //     api_url + 'api/v1.0/platform/payment/defaultCard',
  //     token
  //   );
  //   return { brand: brand, last4: last4 };
  // },
  // postDefaultCardInfo: async (paymentMethod: any, token: any): Promise<any> => {
  //   const { data }: any = await sendPostRequest(
  //     api_url + 'api/v1.0/platform/payment/defaultCard',
  //     { paymentMethod },
  //     token
  //   );
  //   return { data };
  // },

  // postCollectionMethod: async (
  //   paymentMethod: any,
  //   token: any
  // ): Promise<any> => {
  //   const { data }: any = await sendPutRequest(
  //     api_url + 'api/v1.0/platform/payment/paymentMethod',
  //     { paymentMethod: paymentMethod },
  //     token
  //   );
  //   return { data };
  // },

  // getCollectionMethod: async (token: any): Promise<any> => {
  //   const { data }: any = await sendGetRequest(
  //     api_url + 'api/v1.0/platform/payment/paymentMethod',
  //     token
  //   );
  //   return data;
  // },
  // postBillingDetails: async (
  //   token: any,
  //   customFields: any,
  //   companyName: any
  // ): Promise<any> => {
  //   const { data }: any = await sendPostRequest(
  //     api_url + 'api/v1.0/platform/payment/billing',
  //     { customFields, companyName },
  //     token
  //   );
  //   return data;
  // },
  // getBillingDetails: async (token: any): Promise<any> => {
  //   const { data }: any = await sendGetRequest(
  //     api_url + 'api/v1.0/platform/payment/billing',
  //     token
  //   );
  //   return data;
  // },
  // getCompanyName: async (token: any): Promise<any> => {
  //   const { data }: any = await sendGetRequest(
  //     api_url + 'api/v1.0/platform/payment/customerCompanyName',
  //     token
  //   );
  //   return data;
  // },
  // getSellerSubscription: async (token: any): Promise<any> => {
  //   const { data }: any = await sendGetRequest(
  //     api_url + 'api/v1.0/platform/payment/customerSubscription',
  //     token
  //   );
  //   return data;
  // },
  // reasonForAccountDeactivation: async (token: any): Promise<any> => {
  //   const { data }: any = await sendGetRequest(
  //     api_url + 'api/v1.0/platform/payment/reasonForAccountDeactivation',
  //     token
  //   );
  //   return data;
  // },
  // threeDaysTillPayment: async (token: any): Promise<any> => {
  //   const { data }: any = await sendGetRequest(
  //     api_url + 'api/v1.0/platform/payment/threeDaysTillPayment',
  //     token
  //   );
  //   return data;
  // },
  // changeAccountStatus: async (token: any): Promise<any> => {
  //   const { data }: any = await sendPutRequest(
  //     api_url + 'api/v1.0/platform/payment/resumeSubscription',
  //     {},
  //     token
  //   );
  //   return data;
  // },
  // updateInvoice: async (
  //   invoiceId: any,
  //   paymentMethod: any,
  //   reason: any,
  //   token: any
  // ): Promise<any> => {
  //   const { data }: any = await sendPutRequest(
  //     api_url + 'api/v1.0/platform/payment/paymentForOpenInvoice',
  //     { invoiceId, paymentMethod, reason },
  //     token
  //   );
  //   return data;
  // },
});
