import { EnhancedFilters } from 'store/map';

export enum FilterType {
  CheckBox = 'checkbox',
  Radio = 'radio',
  Select = 'select',
  MultiSelect = 'multiSelect',
  GroupMultiSelect = 'groupMultiSelect',
  Range = 'range',
  Input = 'input',
}

export const initialFilters: EnhancedFilters = {
  categories: {
    filterId: 0,
    name: 'Categorii',
    filterVisibleType: FilterType.CheckBox,
    value: null,
    pages: {
      '/piata': FilterType.GroupMultiSelect,
      '/products': null,
    },
    call: 'getCategories',
  },

  counties: {
    filterId: 1,
    name: 'Județe',
    filterVisibleType: FilterType.CheckBox,
    value: null,
    pages: {
      '/piata': FilterType.MultiSelect,
      '/test': FilterType.MultiSelect,
    },
    call: 'getCounties',
  },
  price: {
    filterId: 2,
    name: 'Preț',
    filterVisibleType: FilterType.CheckBox,
    value: null,
    pages: {
      '/produse/legume': FilterType.Range,
      '/test': FilterType.MultiSelect,
    },
    call: undefined,
  },
};
