import { Category } from 'api/map';
import produce from 'immer';
import _ from 'lodash';
import { createSelector } from 'reselect';
import { ProducerInformation, State } from './types';

const toNorm = (str: string) => {
  return str
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
};

export const selectDataIsFetched = createSelector(
  (state: State) => {
    const { producers } = state;
    return producers === undefined ? false : true;
  },
  (fetched) => fetched
);

export const selectProductsAreFetched = createSelector(
  (state: State) => {
    const { products } = state;
    return products === undefined ? false : true;
  },
  (fetched) => fetched
);

export const selectProducersAreFetched = createSelector(
  (state: State) => {
    const { producers } = state;
    return producers === undefined ? false : true;
  },
  (fetched) => fetched
);

export const selectProductsByCategoryAreFetched = createSelector(
  (state: State) => {
    const { productsByCategory } = state;
    return productsByCategory === undefined ? false : true;
  },
  (fetched) => fetched
);

// filter alphabetically
export const selectProductsByCategory = createSelector(
  (state: State, sort: string) => {
    const { productsByCategory } = state;
    let productsArray = productsByCategory || [];

    switch (sort) {
      case 'Distanță':
        productsArray = productsArray.slice().sort(function (a, b) {
          return a.User.distance - b.User.distance;
        });

        break;

      case 'Noutate':
        productsArray = productsArray.slice().sort(function (a, b) {
          return (
            (new Date(b.createdAt).getTime() || 0) -
            (new Date(a.createdAt).getTime() || 0)
          );
        });
        break;
      case 'Preț crescător':
        productsArray = productsArray.slice().sort(function (a, b) {
          return a.price - b.price;
        });
        break;
      case 'Preț descrescător':
        productsArray = productsArray.slice().sort(function (a, b) {
          return b.price - a.price;
        });
        break;
      default:
        break;
    }

    return productsArray;
  },
  (products) => products
);

export const selectCommunityProductsAreFetched = createSelector(
  (state: State) => {
    const { communityProducts } = state;
    return communityProducts === undefined ? false : true;
  },
  (fetched) => fetched
);

export const selectProducts = createSelector(
  (state: State) => {
    const { products = [], filters } = state;
    let productsArray = products || [];
    //ORDERING
    productsArray = productsArray.slice().sort(function (a, b) {
      return a.User.distance - b.User.distance;
    });
    if (!_.isEmpty(filters.order)) {
      switch (filters.order) {
        case 'new':
          productsArray = productsArray.slice().sort(function (a, b) {
            return (
              (new Date(b.createdAt).getTime() || 0) -
              (new Date(a.createdAt).getTime() || 0)
            );
          });
          break;
        case 'reviews':
          break;
        case 'priceUp':
          productsArray = productsArray.slice().sort(function (a, b) {
            return a.price - b.price;
          });
          break;
        case 'priceDown':
          productsArray = productsArray.slice().sort(function (a, b) {
            return b.price - a.price;
          });
          break;
        case 'distance':
          productsArray = productsArray.slice().sort(function (a, b) {
            return a.User.distance - b.User.distance;
          });
          break;

        default:
          productsArray = productsArray.slice().sort(function (a, b) {
            return b.User.distance - a.User.distance;
          });
          break;
      }
    }

    // PRICE FILTERING
    if (
      !isNaN(filters.lowPrice) ||
      !isNaN(filters.highPrice) ||
      filters.deliveries.length > 0
    ) {
      productsArray = productsArray.filter((product) => {
        let isDelivery = false;
        if (filters.deliveries.length > 0) {
          filters.deliveries.forEach((delivery) => {
            if (
              product.User.deliveryTypeId === delivery.id ||
              product.User.deliveryTypeId === 3
            )
              isDelivery = true;
          });
        } else {
          isDelivery = true;
        }
        if (!isNaN(filters.lowPrice) && !isNaN(filters.highPrice))
          return (
            product.price >= filters.lowPrice &&
            product.price <= filters.highPrice &&
            isDelivery
          );
        if (!isNaN(filters.lowPrice))
          return product.price >= filters.lowPrice && isDelivery;
        if (!isNaN(filters.highPrice))
          return product.price <= filters.highPrice && isDelivery;

        return isDelivery;
      });
    }

    // STOCK FILTERS
    if (!isNaN(filters.lowStock) || !isNaN(filters.highStock)) {
      productsArray = productsArray.filter((product) => {
        if (!isNaN(filters.lowStock) || !isNaN(filters.highStock))
          return (
            product.quantity >= filters.lowStock &&
            product.quantity <= filters.highStock
          );
        if (!isNaN(filters.lowStock))
          return product.quantity >= filters.lowStock;
        if (!isNaN(filters.highStock))
          return product.quantity <= filters.highStock;
        return 0;
      });
    }

    // Category Filters
    if (!_.isEmpty(filters.categories) && _.isEmpty(filters.subcategories)) {
      productsArray = productsArray.filter((product) => {
        let isCategory = false;
        filters.categories.forEach((category) => {
          if (
            product.Category.id === category.id ||
            product.Category.parentId === category.id
          )
            isCategory = true;
        });
        return isCategory;
      });
    }

    // Subcategory Filters
    if (!_.isEmpty(filters.subcategories)) {
      productsArray = productsArray.filter((product) => {
        let isCategory = false;
        filters.subcategories.forEach((category) => {
          if (product.Category.id === category.id) isCategory = true;
        });
        return isCategory;
      });
    }

    //Units Filtering
    if (!_.isEmpty(filters.units)) {
      productsArray = productsArray.filter((product) => {
        let isCategory = false;
        filters.units.forEach((unit) => {
          if (product.MeasurementUnit.id === unit.id) isCategory = true;
        });
        return isCategory;
      });
    }

    //Keyword Filtering
    if (!_.isEmpty(filters.keyword)) {
      productsArray = productsArray.filter((product) => {
        let hasKeyword = false;
        // if (!_.isEmpty(filters.keyword)) {

        const {
          firstName = '',
          lastName = '',
          alias = '',
          email = '',
          address = '',
        } = product.User || {
          firstName: '',
          lastName: '',
          alias: '',
          email: '',
          address: '',
        };
        if (
          toNorm(product.name || '').includes(toNorm(filters.keyword)) ||
          toNorm(product.description).includes(toNorm(filters.keyword)) ||
          toNorm(firstName || '').includes(toNorm(filters.keyword)) ||
          toNorm(lastName || '').includes(toNorm(filters.keyword)) ||
          toNorm(alias || '').includes(toNorm(filters.keyword)) ||
          toNorm(email || '').includes(toNorm(filters.keyword)) ||
          toNorm(address || '').includes(toNorm(filters.keyword))
        ) {
          hasKeyword = true;
        }
        return hasKeyword;
      });
    }
    return productsArray || [];
  },
  (products) => products
);

export const selectCommunityProducts = createSelector(
  (state: State) => {
    const { communityProducts = [], filters } = state;
    let productsArray = communityProducts || [];
    //ORDERING
    productsArray = productsArray.slice().sort(function (a, b) {
      return a.User.distance - b.User.distance;
    });
    if (!_.isEmpty(filters.order)) {
      switch (filters.order) {
        case 'new':
          productsArray = productsArray.slice().sort(function (a, b) {
            return (
              (new Date(b.createdAt).getTime() || 0) -
              (new Date(a.createdAt).getTime() || 0)
            );
          });
          break;
        case 'reviews':
          break;
        case 'priceUp':
          productsArray = productsArray.slice().sort(function (a, b) {
            return a.price - b.price;
          });
          break;
        case 'priceDown':
          productsArray = productsArray.slice().sort(function (a, b) {
            return b.price - a.price;
          });
          break;
        case 'distance':
          productsArray = productsArray.slice().sort(function (a, b) {
            return a.User.distance - b.User.distance;
          });
          break;

        default:
          productsArray = productsArray.slice().sort(function (a, b) {
            return b.User.distance - a.User.distance;
          });
          break;
      }
    }

    // PRICE FILTERING
    if (
      !isNaN(filters.lowPrice) ||
      !isNaN(filters.highPrice) ||
      filters.deliveries.length > 0
    ) {
      productsArray = productsArray.filter((product) => {
        let isDelivery = false;
        if (filters.deliveries.length > 0) {
          filters.deliveries.forEach((delivery) => {
            if (
              product.User.deliveryTypeId === delivery.id ||
              product.User.deliveryTypeId === 3
            )
              isDelivery = true;
          });
        } else {
          isDelivery = true;
        }
        if (!isNaN(filters.lowPrice) && !isNaN(filters.highPrice))
          return (
            product.price >= filters.lowPrice &&
            product.price <= filters.highPrice &&
            isDelivery
          );
        if (!isNaN(filters.lowPrice))
          return product.price >= filters.lowPrice && isDelivery;
        if (!isNaN(filters.highPrice))
          return product.price <= filters.highPrice && isDelivery;

        return isDelivery;
      });
    }

    // STOCK FILTERS
    if (!isNaN(filters.lowStock) || !isNaN(filters.highStock)) {
      productsArray = productsArray.filter((product) => {
        if (!isNaN(filters.lowStock) || !isNaN(filters.highStock))
          return (
            product.quantity >= filters.lowStock &&
            product.quantity <= filters.highStock
          );
        if (!isNaN(filters.lowStock))
          return product.quantity >= filters.lowStock;
        if (!isNaN(filters.highStock))
          return product.quantity <= filters.highStock;
        return 0;
      });
    }

    // Category Filters
    if (!_.isEmpty(filters.categories) && _.isEmpty(filters.subcategories)) {
      productsArray = productsArray.filter((product) => {
        let isCategory = false;
        filters.categories.forEach((category) => {
          if (
            product.Category.id === category.id ||
            product.Category.parentId === category.id
          )
            isCategory = true;
        });
        return isCategory;
      });
    }

    // Subcategory Filters
    if (!_.isEmpty(filters.subcategories)) {
      productsArray = productsArray.filter((product) => {
        let isCategory = false;
        filters.subcategories.forEach((category) => {
          if (product.Category.id === category.id) isCategory = true;
        });
        return isCategory;
      });
    }

    //Units Filtering
    if (!_.isEmpty(filters.units)) {
      productsArray = productsArray.filter((product) => {
        let isCategory = false;
        filters.units.forEach((unit) => {
          if (product.MeasurementUnit.id === unit.id) isCategory = true;
        });
        return isCategory;
      });
    }

    //Keyword Filtering
    if (!_.isEmpty(filters.keyword)) {
      productsArray = productsArray.filter((product) => {
        let hasKeyword = false;
        // if (!_.isEmpty(filters.keyword)) {

        const {
          firstName = '',
          lastName = '',
          alias = '',
          email = '',
          address = '',
        } = product.User || {
          firstName: '',
          lastName: '',
          alias: '',
          email: '',
          address: '',
        };
        if (
          toNorm(product.name || '').includes(toNorm(filters.keyword)) ||
          toNorm(product.description).includes(toNorm(filters.keyword)) ||
          toNorm(firstName || '').includes(toNorm(filters.keyword)) ||
          toNorm(lastName || '').includes(toNorm(filters.keyword)) ||
          toNorm(alias || '').includes(toNorm(filters.keyword)) ||
          toNorm(email || '').includes(toNorm(filters.keyword)) ||
          toNorm(address || '').includes(toNorm(filters.keyword))
        ) {
          hasKeyword = true;
        }
        return hasKeyword;
      });
    }
    return productsArray || [];
  },
  (products) => products
);

export const selectFilters = createSelector(
  (state: State) => {
    const { filters } = state;
    return filters;
  },
  (filters) => filters
);

export const selectAllMapInformation = createSelector(
  (state: State) => {
    const { producers, mapInformation, position } = state;
    return /* !_.isEmpty(mapInformation.positionString) && */ producers &&
      !_.isNaN(mapInformation.range) &&
      position.latitude &&
      position.longitude
      ? true
      : false;
  },
  (fetched) => fetched
);

export const selectMapInformation = createSelector(
  (state: State) => {
    const { mapInformation, position } = state;
    return { mapInformation, position };
  },
  (mapInformation) => mapInformation
);

export const selectPosition = createSelector(
  (state: State) => {
    const { position } = state;
    return position;
  },
  (position) => position
);

export const selectCategoriesAreFetched = createSelector(
  (state: State) => {
    const { categories } = state;
    return _.isEmpty(categories) ? false : true;
  },
  (fetched) => fetched
);

export const selectCountiesAreFetched = createSelector(
  (state: State) => {
    const { counties } = state;
    return _.isEmpty(counties) ? false : true;
  },
  (fetched) => fetched
);

export const selectDeliveriesAreFetched = createSelector(
  (state: State) => {
    const { deliveries } = state;
    return _.isEmpty(deliveries) ? false : true;
  },
  (fetched) => fetched
);

export const selectDemandsAreFetched = createSelector(
  (state: State) => {
    const { demands } = state;
    return _.isEmpty(demands) ? false : true;
  },
  (fetched) => fetched
);

export const selectDeliveries = createSelector(
  (state: State) => {
    const { deliveries } = state;
    return deliveries;
  },
  (deliveries) => deliveries
);

export const selectUmAreFetched = createSelector(
  (state: State) => {
    const { units } = state;
    return _.isEmpty(units) ? false : true;
  },
  (fetched) => fetched
);

export const selectEnhacedFiltersAreFetched = createSelector(
  (state: State) => {
    const { enhancedFilters } = state;
    return _.isEmpty(enhancedFilters) ? false : true;
  },
  (fetched) => fetched
);

export const selectCategories = createSelector(
  (state: State) => {
    const { categories } = state;
    return categories.filter((category) => category.parentId === null);
  },
  (categories) => categories
);

export const selectAllCategories = createSelector(
  (state: State) => {
    const { categories } = state;
    return categories;
  },
  (categories) => categories
);

export const selectAllCounties = createSelector(
  (state: State) => {
    const { counties } = state;
    return counties;
  },
  (counties) => counties
);

export const selectUnits = createSelector(
  (state: State) => {
    const { units } = state;
    return units;
  },
  (units) => units
);

export const selectEnhacedFilters = createSelector(
  (state: State) => {
    const { enhancedFilters } = state;
    return enhancedFilters;
  },
  (enhancedFilters) => enhancedFilters
);

export const selectSubcategories = createSelector(
  (state: State) => {
    const { categories, filters } = state;
    let subcategories: Category[] = [];

    filters.categories.forEach((filterCategory) => {
      let parentId = filterCategory.id;
      categories.forEach((category) => {
        if (category.parentId === parentId) subcategories.push(category);
      });
    });
    return subcategories;
  },
  (subcategories) => subcategories
);

export const selectPriceRange = createSelector(
  (state: State) => {
    const { producers = {} } = state;
    let minValue = 100000;
    let maxValue = 0;
    let producersArray: ProducerInformation[] = Object.keys(producers).map(
      (producerKey) => {
        return {
          details: producers[producerKey].details,
          products: producers[producerKey].products,
          id: producerKey,
        };
      }
    );
    producersArray.forEach((producer) => {
      producer.products.forEach((product) => {
        if (product.price < minValue) minValue = product.price;
        if (product.price > maxValue) maxValue = product.price;
      });
    });

    return {
      minValue,
      maxValue,
    };
  },
  (priceRange) => priceRange
);

export const selectProducers = createSelector(
  (state: State) => {
    const { producers = {}, filters, position, mapInformation } = state;

    let producersArray: ProducerInformation[] = Object.keys(producers).map(
      (producerKey) => {
        return {
          details: producers[producerKey].details,
          products: producers[producerKey].products,
          id: producerKey,
        };
      }
    );
    producersArray = producersArray.slice().sort(function (a, b) {
      return a.details.distance - b.details.distance;
    });

    //ORDERING
    if (!_.isEmpty(filters.order)) {
      switch (filters.order) {
        case 'new':
          // producersArray = producersArray.slice().sort(function (a, b) {
          //   return (
          //     (new Date(b.createdAt).getTime() || 0) -
          //     (new Date(a.createdAt).getTime() || 0)
          //   );
          // });
          break;
        case 'reviews':
          break;
        case 'priceUp':
          // producersArray = producersArray.slice().sort(function (a, b) {
          //   return a.price - b.price;
          // });
          break;
        case 'priceDown':
          // producersArray = producersArray.slice().sort(function (a, b) {
          //   return b.price - a.price;
          // });
          break;
        case 'distance':
          break;

        default:
          producersArray = producersArray.slice().sort(function (a, b) {
            return b.details.distance - a.details.distance;
          });
          break;
      }
    }

    // PRICE FILTERING
    if (!isNaN(filters.lowPrice) || !isNaN(filters.highPrice)) {
      producersArray = producersArray.map((producer) => {
        const filteredProducts = producer.products.filter((product) => {
          if (!isNaN(filters.lowPrice) && !isNaN(filters.highPrice))
            return (
              product.price >= filters.lowPrice &&
              product.price <= filters.highPrice
            );
          if (!isNaN(filters.lowPrice))
            return product.price >= filters.lowPrice;
          if (!isNaN(filters.highPrice))
            return product.price <= filters.highPrice;

          return 0;
        });
        return {
          ...producer,
          products: filteredProducts,
        };
      });
    }

    // STOCK FILTERS
    if (!isNaN(filters.lowStock) || !isNaN(filters.highStock)) {
      producersArray = producersArray.map((producer) => {
        const filteredProducts = producer.products.filter((product) => {
          if (!isNaN(filters.lowStock) || !isNaN(filters.highStock))
            return (
              product.quantity >= filters.lowStock &&
              product.quantity <= filters.highStock
            );
          if (!isNaN(filters.lowStock))
            return product.quantity >= filters.lowStock;
          if (!isNaN(filters.highStock))
            return product.quantity <= filters.highStock;
          return 0;
        });
        return {
          ...producer,
          products: filteredProducts,
        };
      });
    }

    // Category Filters
    if (!_.isEmpty(filters.categories) && _.isEmpty(filters.subcategories)) {
      producersArray = producersArray.map((producer) => {
        const filteredProducts = producer.products.filter((product) => {
          let isCategory = false;
          filters.categories.forEach((category) => {
            if (
              product.Category.id === category.id ||
              product.Category.parentId === category.id
            )
              isCategory = true;
          });
          return isCategory;
        });
        return {
          ...producer,
          products: filteredProducts,
        };
      });
    }

    // Subcategory Filters
    if (!_.isEmpty(filters.subcategories)) {
      producersArray = producersArray.map((producer) => {
        const filteredProducts = producer.products.filter((product) => {
          let isCategory = false;
          filters.subcategories.forEach((category) => {
            if (product.Category.id === category.id) isCategory = true;
          });
          return isCategory;
        });
        return {
          ...producer,
          products: filteredProducts,
        };
      });
    }

    //Units Filtering
    if (!_.isEmpty(filters.units)) {
      producersArray = producersArray.map((producer) => {
        const filteredProducts = producer.products.filter((product) => {
          let isCategory = false;
          filters.units.forEach((unit) => {
            if (product.MeasurementUnit.id === unit.id) isCategory = true;
          });
          return isCategory;
        });
        return {
          ...producer,
          products: filteredProducts,
        };
      });
    }

    // Category Filters
    if (!_.isEmpty(filters.keyword)) {
      producersArray = producersArray.map((producer) => {
        let hasProducer = false;
        const {
          firstName = '',
          lastName = '',
          email = '',
          address = '',
          alias = '',
        } = producer.details;
        if (
          toNorm(firstName || '').includes(toNorm(filters.keyword)) ||
          toNorm(lastName || '').includes(toNorm(filters.keyword)) ||
          toNorm(email || '').includes(toNorm(filters.keyword)) ||
          toNorm(address || '').includes(toNorm(filters.keyword)) ||
          toNorm(alias || '').includes(toNorm(filters.keyword))
        )
          hasProducer = true;

        if (hasProducer)
          return {
            ...producer,
            products: producer.products,
          };

        const filteredProducts = producer.products.filter((product) => {
          let hasKeyword = false;
          if (
            toNorm(product.name).includes(toNorm(filters.keyword)) ||
            toNorm(product.description).includes(toNorm(filters.keyword))
          )
            hasKeyword = true;
          return hasKeyword;
        });

        return {
          ...producer,
          products: filteredProducts,
        };
      });
    }

    producersArray = producersArray.filter((producer) => {
      let isDelivery = false;
      if (filters.deliveries.length > 0) {
        if (filters.deliveries.length > 0) {
          filters.deliveries.forEach((delivery) => {
            if (
              (producer.details.deliveryTypeId === delivery.id ||
                producer.details.deliveryTypeId === 3) &&
              !_.isEmpty(producer.products)
            )
              isDelivery = true;
          });
          return isDelivery;
        }
      }
      return !_.isEmpty(producer.products);
    });
    const changeProducer = (producers: ProducerInformation[]) => {
      let newProducers: ProducerInformation[] = [];
      producers.forEach((producer) => {
        let positionLatitude = producer.details.latitude;
        if (
          newProducers.find(
            (prod) =>
              prod.details.id !== producer.details.id &&
              producer.details.latitude === prod.details.latitude &&
              producer.details.longitude === prod.details.longitude
          )
        ) {
          positionLatitude += 0.00001;
        }
        newProducers.push(
          produce(producer, (draft) => {
            draft.details.latitude = positionLatitude;
          })
        );
      });
      return newProducers;
    };

    return {
      producersArray: changeProducer(producersArray),
      position,
      mapInformation,
    };
  },
  (producerInfo) => producerInfo
);

export const selectAllDemands = createSelector(
  (state: State) => {
    const { demands = [] } = state;
    return demands;
  },
  (demands) => demands
);

export const selectDemands = createSelector(
  (state: State) => {
    const { demands = [], filters, enhancedFilters } = state;
    let demandsArray = demands || [];

    //Ordering
    demandsArray = _.sortBy(demandsArray, [(o) => o.createdAt]);
    demandsArray = _.sortBy(demandsArray, [(o) => o.likes.length]).reverse();
    if (!_.isEmpty(filters.order)) {
      demandsArray = _.sortBy(demandsArray, [(o) => o.createdAt]);
      switch (filters.order) {
        case 'popularity':
          demandsArray = _.sortBy(demandsArray, [
            (o) => o.likes.length,
          ]).reverse();
          break;
        case 'relevance':
          demandsArray = _.sortBy(demandsArray, [(o) => o.likes.length]);
          demandsArray = _.sortBy(demandsArray, [
            (o) => o.liked.length,
          ]).reverse();
          break;
        default:
          break;
      }
    }

    // Market Categories Filters
    if (!_.isEmpty(enhancedFilters)) {
      if (!_.isEmpty(enhancedFilters['categories'].value)) {
        demandsArray = demandsArray.filter((demand) => {
          return enhancedFilters['categories'].value.some((marketFilter) => {
            return marketFilter.id === demand.Category.id;
          });
        });
      }
    }

    // Counties Filters
    if (!_.isEmpty(enhancedFilters)) {
      if (!_.isEmpty(enhancedFilters['counties'].value)) {
        demandsArray = demandsArray.filter((demand) => {
          return enhancedFilters['counties'].value.some((marketFilter) => {
            let containesCounty;
            demand.counties &&
              demand.counties.forEach((county) => {
                if (marketFilter && county.id === marketFilter.id) {
                  containesCounty = true;
                }
              });
            return containesCounty ? true : false;
          });
        });
      }
    }

    //Keyword Filtering
    if (!_.isEmpty(filters.keyword)) {
      demandsArray = demandsArray.filter((demand) => {
        let hasKeyword = false;
        const { firstName = '', lastName = '', alias = '' } = demand.User;
        if (
          toNorm(demand.name || '').includes(toNorm(filters.keyword)) ||
          toNorm(demand.description).includes(toNorm(filters.keyword)) ||
          toNorm(firstName || '').includes(toNorm(filters.keyword)) ||
          toNorm(lastName || '').includes(toNorm(filters.keyword)) ||
          toNorm(alias || '').includes(toNorm(filters.keyword))
        ) {
          hasKeyword = true;
        }
        return hasKeyword;
      });
    }

    return demandsArray || [];
  },
  (demands) => demands
);

export const selectUsersWithDemands = createSelector(
  (state: State) => {
    const { demands } = state;
    const usersWithDemands = demands && demands.map((demand) => demand.User);
    return _.uniqBy(usersWithDemands, 'id');
  },
  (usersWithDemands) => usersWithDemands
);

// label

export const selectAllLabels = createSelector(
  (state: State) => {
    const { labels = [] } = state;

    return labels;
  },
  (labels) => labels
);

export const selectCategoriesByLabel = createSelector(
  (state: State, label: string) => {
    const { labels = [] } = state;
    const specificLabel = labels.filter((item) => item.slug === label)[0];
    const categories =
      specificLabel &&
      specificLabel.categories.map((category) => ({
        value: category.id,
        label: category.name,
      }));

    return categories;
  },

  (categories) => categories
);

export const selectLabelsAreFetched = createSelector(
  (state: State) => {
    const { labels } = state;
    return _.isEmpty(labels) ? false : true;
  },
  (fetched) => fetched
);
