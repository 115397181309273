import produce from 'immer';
import { ActionTypes } from '.';
import initialState from './initialState';
import { State, Actions } from './types';

export default (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case ActionTypes.GET_META: {
      const {
        data: {
          data: { attributes },
        },
      } = action.payload;

      const newObj = {
        showCommunities: attributes.showCommunities,
        showMarket: attributes.showMarket,
        showPopularItems: attributes.showPopularItems,
      };

      return newObj;
    }
    default: {
      return state;
    }
  }
};
