import React, { Suspense, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { configureStore } from 'store/store';
import * as serviceWorker from './serviceWorker';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { IntlProvider } from 'react-intl';
import AppLocale from './lang';

const App = React.lazy(() => import(/* webpackChunkName: "App" */ './App'));
const ViewError = React.lazy(() =>
  import(/* webpackChunkName: "views-error" */ './views/error')
);
const locale = 'en';

const currentAppLocale = AppLocale[locale];
const RouteV6Adapter = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const adaptedHistory = useMemo(
    () => ({
      push: ({ search, state }) => navigate({ search }, { state }),
      replace: ({ search, state }) =>
        navigate({ search }, { replace: true, state }),
    }),
    [navigate]
  );

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return children({ history: adaptedHistory, location });
};

ReactDOM.render(
  <Provider store={configureStore()}>
    <Suspense fallback={<div className="loading" />}>
      <Router>
        <QueryParamProvider ReactRouterRoute={RouteV6Adapter}>
          <App />
        </QueryParamProvider>
      </Router>
    </Suspense>
  </Provider>,
  document.getElementById('root')
);
/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
