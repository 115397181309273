import {
  Cart,
  GetCartResponse,
  GetProducersResponse,
  Order,
  Producers,
} from 'api/map';
import { sendGetRequest, sendPostRequest, sendPutRequest } from 'api/utils';
import { sendDeleteRequest, sendImageRequest } from 'api/utils/network';
import { api_url } from '../utils/consts';
import {
  Address,
  AddressResponse,
  BasicUserInformation,
  DeliveryInfo,
  DemandEmailInformation,
  DemandInformation,
  DemandInsertResponse,
  DemandLikeInformation,
  DemandResponse,
  Demands,
  EmailCheckInformation,
  EmailCheckInformationResponse,
  ExtendedOfferInformation,
  ExtendedOrderInformation,
  ExtendedStockInformation,
  GetSubscriptionsResponse,
  GiveLikeResponse,
  InsertProfilePhotoResponse,
  Like,
  LoginInformation,
  LoginResponse,
  OfferInformation,
  OfferInsertResponse,
  OfferResponse,
  OrderInsertResponse,
  OrderResponse,
  PickupAddressReponse,
  PostDeliveryInfoResponse,
  RegisterResponse,
  sendChatEmailResponse,
  StockInformation,
  StockInsertResponse,
  StockResponse,
  Subscription,
  SubscriptionInformation,
  SubscriptionResponse,
  UserInformation,
} from './types';

export default () => ({
  checkedIfLogged: async (email: string): Promise<EmailCheckInformation> => {
    const { data }: EmailCheckInformationResponse = await sendGetRequest(
      api_url + 'api/v1.0/platform/user/email/' + email
    );
    return data;
  },
  login: async (userInfo: BasicUserInformation): Promise<LoginInformation> => {
    const { data }: LoginResponse = await sendPostRequest(
      api_url + 'api/v1.0/platform/user/login',
      userInfo
    );
    return data;
  },
  register: async (userInfo: UserInformation): Promise<string> => {
    const { data }: RegisterResponse = await sendPostRequest(
      api_url + 'api/v1.0/platform/user/signup',
      userInfo
    );
    return data;
  },
  forgotPassword: async (email: string): Promise<string> => {
    const { data }: RegisterResponse = await sendPostRequest(
      api_url + 'api/v1.0/platform/user/forgotPassword',
      { email: email }
    );
    return data;
  },
  inviteUser: async (token: string, email: string): Promise<string> => {
    const { data }: RegisterResponse = await sendPostRequest(
      api_url + 'api/v1.0/platform/user/affiliate',
      { email: email },
      token
    );
    return data;
  },
  getAddresses: async (id: number): Promise<Address> => {
    const { data }: AddressResponse = await sendGetRequest(
      api_url + 'api/v1.0/platform/address/' + id
    );
    return data;
  },

  getSubscriptions: async (): Promise<Subscription[]> => {
    const token = localStorage.getItem('token');
    const { data }: GetSubscriptionsResponse = await sendGetRequest(
      api_url + 'api/v1.0/platform/demand/getUserSubscriptions',
      token || ''
    );
    return data;
  },
  setUserInformation: async (
    token: string,
    userInfo: Partial<UserInformation>
  ): Promise<Producers[]> => {
    const { data }: GetProducersResponse = await sendPutRequest(
      api_url + 'api/v1.0/platform/user',
      userInfo,
      token
    );
    //Throws an error
    return data;
  },

  postAddresses: async (address: Address): Promise<Address> => {
    const token = localStorage.getItem('token');
    const { data }: AddressResponse = await sendPostRequest(
      api_url + 'api/v1.0/platform/address',
      address,
      token || ''
    );
    return data;
  },
  updateAddresses: async (
    id: string,
    address: Partial<Address>
  ): Promise<Address> => {
    const token = localStorage.getItem('token');

    const { data }: AddressResponse = await sendPutRequest(
      api_url + 'api/v1.0/platform/address/' + id,
      address,
      token || ''
    );
    return data;
  },
  changePassword: async (
    token: string,
    newPassword: {
      uuCode: string;
      password: string;
    }
  ): Promise<string> => {
    const { data }: RegisterResponse = await sendPostRequest(
      api_url + 'api/v1.0/platform/user/changePassword',
      newPassword,
      token
    );
    return data;
  },
  confirmEmail: async (email: string): Promise<string> => {
    const { data }: RegisterResponse = await sendPostRequest(
      api_url + 'api/v1.0/platform/user/confirmEmail',
      { email: email }
    );
    return data;
  },
  confirmAccount: async (
    token: string,
    body: {
      uuCode: string;
    }
  ): Promise<string> => {
    const { data }: RegisterResponse = await sendPostRequest(
      api_url + 'api/v1.0/platform/user/confirmAccount',
      body,
      token
    );
    return data;
  },
  getUserInformation: async (token: string): Promise<Producers[]> => {
    const { data }: GetProducersResponse = await sendGetRequest(
      api_url + 'api/v1.0/platform/user/',
      token
    );
    return data;
  },
  insertStock: async (
    id: number,
    token: string,
    stockData: StockInformation
  ): Promise<ExtendedStockInformation> => {
    const { data }: StockInsertResponse = await sendPostRequest(
      api_url + 'api/v1.0/platform/stock/' + id,
      stockData,
      token
    );
    return data;
  },
  insertOffer: async (
    token: string,
    offerData: OfferInformation
  ): Promise<ExtendedOfferInformation> => {
    const { data }: OfferInsertResponse = await sendPostRequest(
      api_url + 'api/v1.0/platform/offer',
      offerData,
      token
    );
    return data;
  },
  insertDemand: async (
    token: string,
    demandData: DemandInformation
  ): Promise<Demands[]> => {
    const { data }: DemandInsertResponse = await sendPostRequest(
      api_url + 'api/v1.0/platform/demand',
      demandData,
      token
    );
    return data;
  },

  insertSubscription: async (
    subscriptionData: SubscriptionInformation
  ): Promise<Subscription> => {
    const token = localStorage.getItem('token');
    const { data }: SubscriptionResponse = await sendPostRequest(
      api_url + 'api/v1.0/platform/demand/subscribeDemand/',
      subscriptionData,
      token || ''
    );
    return data;
  },

  updateSubscription: async (
    subscriptionId: number,
    subscriptionData: any
  ): Promise<Subscription> => {
    const token = localStorage.getItem('token');
    const { data }: SubscriptionResponse = await sendPutRequest(
      api_url +
        'api/v1.0/platform/demand/updateDemandSubscription/' +
        subscriptionId,
      subscriptionData,
      token || ''
    );
    return data;
  },

  giveLike: async (
    token: string,
    likeData: DemandLikeInformation
  ): Promise<Like> => {
    const { data }: GiveLikeResponse = await sendPostRequest(
      api_url + 'api/v1.0/platform/demand/like',
      likeData,
      token
    );
    return data;
  },

  getDemands: async (token: string, id: number): Promise<Demands[]> => {
    const { data }: DemandInsertResponse = await sendGetRequest(
      api_url + 'api/v1.0/platform/demand?userId=' + id,
      token
    );
    return data;
  },
  insertOrder: async (
    token: string,
    orderData: Order
  ): Promise<ExtendedOrderInformation> => {
    const { data }: OrderInsertResponse = await sendPostRequest(
      api_url + 'api/v1.0/platform/order',
      orderData,
      token
    );
    return data;
  },
  insertCart: async (token: string, cartData: any): Promise<Cart> => {
    const { data } = await sendPostRequest(
      api_url + 'api/v1.0/platform/cart',
      cartData,
      token
    );

    return data;
  },
  getCart: async (token: string): Promise<GetCartResponse> => {
    const reposne = await sendGetRequest(
      api_url + 'api/v1.0/platform/cart',
      token
    );
    return reposne;
  },
  emptyCart: async (token: string) => {
    await sendDeleteRequest(api_url + 'api/v1.0/platform/cart', token);
  },
  getPickupAddress: async (id: number): Promise<PickupAddressReponse> => {
    const { data } = await sendGetRequest(
      api_url + 'api/v1.0/platform/address/' + id
    );

    return data;
  },
  getOrderCustomer: async (
    token: string
  ): Promise<ExtendedOrderInformation> => {
    const { data }: OrderResponse = await sendGetRequest(
      api_url + 'api/v1.0/platform/order/customer',
      token
    );
    return data;
  },
  getOrderSeller: async (token: string): Promise<ExtendedOrderInformation> => {
    const { data }: OrderResponse = await sendGetRequest(
      api_url + 'api/v1.0/platform/order/seller',
      token
    );
    return data;
  },
  getSingleOrderSeller: async (
    token: string,
    id: number
  ): Promise<ExtendedOrderInformation> => {
    const { data }: OrderResponse = await sendGetRequest(
      api_url + 'api/v1.0/platform/order/' + id,
      token
    );
    return data;
  },
  getSingleOrder: async (
    token: string,
    id: number
  ): Promise<ExtendedOrderInformation> => {
    const { data }: OrderResponse = await sendGetRequest(
      api_url + 'api/v1.0/platform/order/main/' + id,
      token
    );
    return data;
  },
  putOrderStatus: async (
    id: number,
    token: string,
    status: { statusId: number }
  ): Promise<ExtendedOrderInformation> => {
    const { data }: OrderResponse = await sendPutRequest(
      api_url + 'api/v1.0/platform/order/' + id,
      status,
      token
    );
    return data;
  },
  insertImageStock: async (
    id: number,
    token: string,
    formData: FormData
  ): Promise<StockInformation> => {
    const { data }: StockResponse = await sendImageRequest(
      api_url + 'api/v1.0/platform/stock/image/' + id,
      formData,
      token
    );
    return data;
  },
  insertProfilePhoto: async (
    token: string,
    formData: FormData
  ): Promise<string> => {
    const { data }: InsertProfilePhotoResponse = await sendImageRequest(
      api_url + 'api/v1.0/platform/user/image/',
      formData,
      token
    );
    return data;
  },
  setStockInformation: async (
    id: number,
    token: string,
    stockInfo: Partial<StockInformation>
  ): Promise<StockInformation> => {
    const { data }: StockResponse = await sendPutRequest(
      api_url + 'api/v1.0/platform/stock/' + id,
      stockInfo,
      token
    );
    return data;
  },
  setDemandInformation: async (
    id: number,
    token: string,
    demandInfo: Partial<DemandInformation>
  ): Promise<DemandInformation> => {
    const { data }: DemandResponse = await sendPutRequest(
      api_url + 'api/v1.0/platform/demand/' + id,
      demandInfo,
      token
    );
    return data;
  },
  setOfferInformation: async (
    id: number,
    token: string,
    offerInfo: Partial<OfferInformation>
  ): Promise<OfferInformation> => {
    const { data }: OfferResponse = await sendPutRequest(
      api_url + 'api/v1.0/platform/offer/' + id,
      offerInfo,
      token
    );
    return data;
  },
  deleteStock: async (id: number, token: string): Promise<string> => {
    const { data }: RegisterResponse = await sendDeleteRequest(
      api_url + 'api/v1.0/platform/stock/' + id,
      token
    );
    return data;
  },
  deleteOffer: async (id: number, token: string): Promise<string> => {
    const { data }: RegisterResponse = await sendDeleteRequest(
      api_url + 'api/v1.0/platform/demand' + id,
      token
    );
    return data;
  },
  deleteAccount: async (token: string): Promise<string> => {
    const { data }: RegisterResponse = await sendDeleteRequest(
      api_url + 'api/v1.0/platform/user',
      token
    );
    return data;
  },
  unsubscribe: async (
    token: string,
    body: {
      uuCode: string;
    }
  ): Promise<string> => {
    const { data }: RegisterResponse = await sendPostRequest(
      api_url + `api/v1.0/platform/newsletter/unsubscribe/`,
      body,
      token
    );
    return data;
  },
  postDeliveryInfo: async (
    deliveryInfo: DeliveryInfo
  ): Promise<DeliveryInfo[]> => {
    const token = localStorage.getItem('token') || '';
    const { data }: PostDeliveryInfoResponse = await sendPostRequest(
      api_url + 'api/v1.0/platform/deliveryInfo',
      deliveryInfo,
      token
    );
    return data;
  },
  putDeliveryInfo: async (
    deliveryInfo: DeliveryInfo,
    id: number
  ): Promise<DeliveryInfo[]> => {
    const token = localStorage.getItem('token') || '';
    const { data }: PostDeliveryInfoResponse = await sendPutRequest(
      api_url + 'api/v1.0/platform/deliveryInfo/' + id,
      deliveryInfo,
      token
    );
    return data;
  },

  sendChatEmail: async (emailInfo: DemandEmailInformation): Promise<string> => {
    const token = localStorage.getItem('token') || '';
    const { data }: sendChatEmailResponse = await sendPostRequest(
      api_url + 'api/v1.0/platform/demand/sendEmail',
      emailInfo,
      token
    );
    return data;
  },
});
