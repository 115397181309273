import { createSelector } from 'reselect';
import { State } from './types';

export const selectMetas = createSelector(
  (state: State) => {
    const meta = state;
    return meta;
  },
  (meta) => meta
);

export const selectMetasAreFetched = createSelector(
  (state: State) => {
    const meta = state;

    return meta.showCommunities === undefined ? false : true;
  },
  (fetched) => fetched
);

export const selectShowPopularItems = createSelector(
  (state: State) => {
    const items = state;

    return items.showPopularItems;
  },
  (items) => items
);

export const selectShowMarket = createSelector(
  (state: State) => {
    const items = state;

    return items.showMarket;
  },
  (items) => items
);

export const selectshowCommunities = createSelector(
  (state: State) => {
    const items = state;

    return items.showCommunities;
  },
  (items) => items
);
