import { State } from './types';

const initialState: State = {
  activities: [],
  errors: [],
  snack: {
    type: undefined,
    message: '',
    status: 0,
  },
};

export default initialState;
