import {
  BasicUserInformation,
  DemandInformation,
  DemandLikeInformation,
  Demands,
  ExtendedDemandInformation,
  ExtendedOfferInformation,
  ExtendedStockInformation,
  OfferInformation,
  StockInformation,
  UserInformation,
} from 'api/account';
import {
  Cart,
  Category,
  GetCartResponse,
  Order,
  Producers,
  Products,
  Unit,
} from 'api/map';
import { Action } from 'redux';

export interface StateUser extends UserInformation {
  accessToken: string;
}

export interface State {
  user: StateUser;
  products: Products[];
  cart: Cart[];
  fetchedCart: boolean;
  fetchedDemands: boolean;
  demands: Demands[];
}

export enum ActionTypes {
  LOGIN = 'store/account/LOGIN',
  SIGN_UP = 'store/account/SIGN_UP',
  SIGN_OUT = 'store/account/SIGN_OUT',
  GET_USER_INFORMATION = 'store/account/GET_USER_INFROMATION',
  UPDATE_USER_INFORMATION = 'store/account/UPDATE_USER_INFORMATION',
  INSERT_STOCK = 'store/account/INSERT_STOCK',
  INSERT_PROFILE_PHOTO = 'store/account/INSERT_PROFILE_PHOTO',
  INSERT_OFFER = 'store/account/INSERT_OFFER',
  INSERT_DEMAND = 'store/account/INSERT_DEMAND',
  LIKE_DEMAND = 'storestore/account/LIKE_DEMAND',
  GET_DEMANDS = 'store/account/GET_DEMANDS',
  UPDATE_STOCK_INFORMATION = 'store/account/UPDATE_STOCK_INFORMATION',
  UPDATE_DEMAND_INFORMATION = 'store/account/UPDATE_DEMAND_INFORMATION',
  UPDATE_LIKE_INFORMATION = 'store/account/UPDATE_LIKE_INFORMATION',
  UPDATE_OFFER_INFORMATION = 'store/account/UPDATE_OFFER_INFORMATION',
  DELETE_STOCK = 'store/account/DELETE_STOCK',
  DELETE_OFFER = 'store/account/DELETE_OFFER',
  INSERT_CART = 'store/account/INSERT_CART',
  DELETE_CART = 'store/account/DELETE_CART',
  GET_CART = 'store/account/GET_CART',
  EMPTY_CART = 'store/account/EMPTY_CART',
  PLACE_ORDER = 'store/account/PLACE_ORDER',
  GET_CART_ANONYMOUS = 'store/account/GET_CART_ANONYMOUS',
  GET_DEMAND_LIKES = 'store/account/GET_DEMAND_LIKES',
}

export interface LoginAction extends Action {
  type: ActionTypes.LOGIN;
  payload: {
    data: BasicUserInformation;
    token: string;
  };
}

export interface SignUpAction extends Action {
  type: ActionTypes.SIGN_UP;
  payload: {
    data: UserInformation;
    token: string;
  };
}

export interface GetUserInformation extends Action {
  type: ActionTypes.GET_USER_INFORMATION;
  payload: {
    token: string;
    data: Producers[];
  };
}

export interface GetDemands extends Action {
  type: ActionTypes.GET_DEMANDS;
  payload: {
    token: string;
    data: Demands[];
  };
}

export interface UpdateUserInformation extends Action {
  type: ActionTypes.UPDATE_USER_INFORMATION;
  payload: {
    data: Partial<UserInformation>;
    token: string;
  };
}

export interface UpdateStockInformation extends Action {
  type: ActionTypes.UPDATE_STOCK_INFORMATION;
  payload: {
    id: number;
    data: ExtendedStockInformation;
    categories: Category[];
    measurements: Unit[];
  };
}

export interface UpdateDemandInformation extends Action {
  type: ActionTypes.UPDATE_DEMAND_INFORMATION;
  payload: {
    id: number;
    data: ExtendedDemandInformation;
    categories: Category[];
  };
}

export interface UpdateLikeInformation extends Action {
  type: ActionTypes.UPDATE_LIKE_INFORMATION;
  payload: {
    demandId: number;
    userId: number;
    updatedAt: string;
    createdAt: string;
  };
}

// export interface UpdateDemandInformation extends Action {
//   type: ActionTypes.UPDATE_STOCK_INFORMATION;
//   payload: {
//     id: number;
//     data: ExtendedStockInformation;
//     categories: Category[];
//     measurements: Unit[];
//   };
// }

export interface UpdateOfferInformation extends Action {
  type: ActionTypes.UPDATE_OFFER_INFORMATION;
  payload: {
    id: number;
    data: ExtendedOfferInformation;
  };
}

export interface DeleteStock extends Action {
  type: ActionTypes.DELETE_STOCK;
  payload: {
    id: number;
  };
}

export interface DeleteOffer extends Action {
  type: ActionTypes.DELETE_OFFER;
  payload: {
    id: number;
  };
}

export interface SignOutAction extends Action {
  type: ActionTypes.SIGN_OUT;
}

export interface InsertStockAction extends Action {
  type: ActionTypes.INSERT_STOCK;
  payload: {
    data: StockInformation;
    image: FormData;
  };
}

export interface InsertProfilePhotoAction extends Action {
  type: ActionTypes.INSERT_PROFILE_PHOTO;
  payload: {
    image: FormData;
  };
}

export interface InsertOfferAction extends Action {
  type: ActionTypes.INSERT_OFFER;
  payload: {
    data: OfferInformation;
  };
}

export interface InsertDemandAction extends Action {
  type: ActionTypes.INSERT_DEMAND;
  payload: {
    data: DemandInformation;
  };
}

export interface LikeDemandAction extends Action {
  type: ActionTypes.LIKE_DEMAND;
  payload: {
    data: DemandLikeInformation;
  };
}

export interface InsertCartAction extends Action {
  type: ActionTypes.INSERT_CART;
  payload: {
    data: Cart;
  };
}

export interface DeleteCartAction extends Action {
  type: ActionTypes.DELETE_CART;
  payload: {
    productId: number;
  };
}

export interface EmptyCartAction extends Action {
  type: ActionTypes.EMPTY_CART;
}

export interface PlaceOrderAction extends Action {
  type: ActionTypes.PLACE_ORDER;
  payload: {
    data: Order;
  };
}

export interface GetCartAction extends Action {
  type: ActionTypes.GET_CART;
  payload: {
    data: GetCartResponse;
  };
}

export interface GetCartActionAnonymous extends Action {
  type: ActionTypes.GET_CART_ANONYMOUS;
  payload: {
    data: Cart[];
  };
}

export type Actions =
  | LoginAction
  | SignUpAction
  | SignOutAction
  | GetUserInformation
  | UpdateUserInformation
  | UpdateStockInformation
  | InsertStockAction
  | InsertProfilePhotoAction
  | InsertOfferAction
  | DeleteStock
  | DeleteOffer
  | InsertCartAction
  | DeleteCartAction
  | PlaceOrderAction
  | EmptyCartAction
  | GetCartAction
  | GetCartActionAnonymous
  | LikeDemandAction
  | UpdateDemandInformation
  | UpdateLikeInformation
  | GetDemands;
