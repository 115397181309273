import { Action } from 'redux';

export interface State {
  containerClassnames: string;
  subHiddenBreakpoint: number;
  menuHiddenBreakpoint: number;
  menuClickCount: number;
  selectedMenuHasSubItems: boolean;
}

export enum ActionTypes {
  MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES',
  MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME',
  MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU',
  MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES',
  MENU_CHANGE_HAS_SUB_ITEM_STATUS = 'MENU_CHANGE_HAS_SUB_ITEM_STATUS',
}

export interface ChangeHasSubItemStatusAction extends Action {
  type: ActionTypes.MENU_CHANGE_HAS_SUB_ITEM_STATUS;
  payload;
}

export interface ChangeDefaultClasssesAction extends Action {
  type: ActionTypes.MENU_CHANGE_DEFAULT_CLASSES;
  payload: any;
}
export interface MenuContainerAddAction extends Action {
  type: ActionTypes.MENU_CONTAINER_ADD_CLASSNAME;
  payload: any;
}
export interface MenuMobileClickAction extends Action {
  type: ActionTypes.MENU_CLICK_MOBILE_MENU;
  payload: any;
}

export interface MenuSetClassNameAction extends Action {
  type: ActionTypes.MENU_SET_CLASSNAMES;
  payload: any;
}

export type Actions =
  | ChangeHasSubItemStatusAction
  | ChangeDefaultClasssesAction
  | MenuContainerAddAction
  | MenuMobileClickAction
  | MenuSetClassNameAction;
