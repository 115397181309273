import { Action } from 'redux';

export interface Activity {
  uuid: string;
  type: string | null;
  payload?: any;
}

export interface ActivityError extends Activity {
  error: string | null;
  timeout?: number;
}

export interface SnackMessage {
  type: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined;
  message: string;
  status: number;
}

export interface State {
  activities: Activity[];
  errors: ActivityError[];
  snack: SnackMessage;
}

export enum ActionTypes {
  ACTIVITY = 'request/activities/ACTIVITY',
  ERROR = 'request/activities/ERROR',
  SET_SNACK = 'request/activities/SET_SNACK',
}

export interface ActivityAction extends Action {
  type: ActionTypes.ACTIVITY;
  payload: Activity;
}

export interface ActivityErrorAction extends Action {
  type: ActionTypes.ERROR;
  payload: ActivityError;
}

export interface SnackAction extends Action {
  type: ActionTypes.SET_SNACK;
  payload: SnackMessage;
}

export type Actions = ActivityAction | ActivityErrorAction | SnackAction;
