import produce from 'immer';
import initialState from './initialState';
import { Actions, ActionTypes, State } from './types';

export default (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case ActionTypes.ACTIVITY: {
      // return produce(state, draft => {
      //     if (action.payload.type)
      //     draft.activities.push(action.payload)
      // })
      return {
        ...state,
        activities: action.payload.type
          ? [...state.activities, action.payload]
          : state.activities.filter(
              (activity) => action.payload.uuid !== activity.uuid
            ),
      };
    }

    case ActionTypes.ERROR: {
      // return produce(state, draft => {
      //     if (action.payload.type)
      //     draft.errors.push(action.payload)
      // })
      return {
        ...state,
        errors: action.payload.type
          ? [...state.errors, action.payload]
          : state.errors.filter((error) => action.payload.uuid !== error.uuid),
      };
    }

    case ActionTypes.SET_SNACK: {
      return produce(state, (draft) => {
        draft.snack = action.payload;
      });
    }

    default: {
      return state;
    }
  }
};
