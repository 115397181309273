import { ActionCreator } from 'redux';
import {
  ActionTypes,
  ChangeDefaultClasssesAction,
  ChangeHasSubItemStatusAction,
  MenuContainerAddAction,
  MenuMobileClickAction,
  MenuSetClassNameAction,
} from './types';

export const changeSelectedMenuHasSubItems: ActionCreator<ChangeHasSubItemStatusAction> = (
  payload
) => ({
  type: ActionTypes.MENU_CHANGE_HAS_SUB_ITEM_STATUS,
  payload,
});

export const changeDefaultClassnames: ActionCreator<ChangeDefaultClasssesAction> = (
  strCurrentClasses
) => ({
  type: ActionTypes.MENU_CHANGE_DEFAULT_CLASSES,
  payload: strCurrentClasses,
});

export const addContainerClassname: ActionCreator<MenuContainerAddAction> = (
  classname,
  strCurrentClasses: string
) => {
  const newClasses = !strCurrentClasses.indexOf(classname)
    ? strCurrentClasses
    : `${strCurrentClasses} ${classname}`;
  return {
    type: ActionTypes.MENU_CONTAINER_ADD_CLASSNAME,
    payload: newClasses,
  };
};

export const clickOnMobileMenu: ActionCreator<MenuMobileClickAction> = (
  strCurrentClasses: string
) => {
  const currentClasses: string[] = strCurrentClasses
    ? strCurrentClasses
        .split(' ')
        .filter((x) => x !== '' && x !== 'sub-show-temporary')
    : [''];
  let nextClasses = '';
  if (currentClasses.includes('main-show-temporary')) {
    nextClasses = currentClasses
      .filter((x) => x !== 'main-show-temporary')
      .join(' ');
  } else {
    nextClasses = `${currentClasses.join(' ')} main-show-temporary`;
  }
  return {
    type: ActionTypes.MENU_CLICK_MOBILE_MENU,
    payload: { containerClassnames: nextClasses, menuClickCount: 0 },
  };
};

export const setContainerClassnames: ActionCreator<MenuSetClassNameAction> = (
  clickIndex,
  strCurrentClasses,
  selectedMenuHasSubItems
) => {
  const currentClasses = strCurrentClasses
    ? strCurrentClasses.split(' ').filter((x) => x !== '')
    : '';
  let nextClasses = '';
  if (!selectedMenuHasSubItems) {
    if (
      currentClasses.includes('menu-default') &&
      (clickIndex % 4 === 0 || clickIndex % 4 === 3)
    ) {
      clickIndex = 1;
    }
    if (currentClasses.includes('menu-sub-hidden') && clickIndex % 4 === 2) {
      clickIndex = 0;
    }
    if (
      currentClasses.includes('menu-hidden') &&
      (clickIndex % 4 === 2 || clickIndex % 4 === 3)
    ) {
      clickIndex = 0;
    }
  }

  if (clickIndex % 4 === 0) {
    if (
      currentClasses.includes('menu-default') &&
      currentClasses.includes('menu-sub-hidden')
    ) {
      nextClasses = 'menu-default menu-sub-hidden';
    } else if (currentClasses.includes('menu-default')) {
      nextClasses = 'menu-default';
    } else if (currentClasses.includes('menu-sub-hidden')) {
      nextClasses = 'menu-sub-hidden';
    } else if (currentClasses.includes('menu-hidden')) {
      nextClasses = 'menu-hidden';
    }
    clickIndex = 0;
  } else if (clickIndex % 4 === 1) {
    if (
      currentClasses.includes('menu-default') &&
      currentClasses.includes('menu-sub-hidden')
    ) {
      nextClasses = 'menu-default menu-sub-hidden main-hidden sub-hidden';
    } else if (currentClasses.includes('menu-default')) {
      nextClasses = 'menu-default sub-hidden';
    } else if (currentClasses.includes('menu-sub-hidden')) {
      nextClasses = 'menu-sub-hidden main-hidden sub-hidden';
    } else if (currentClasses.includes('menu-hidden')) {
      nextClasses = 'menu-hidden main-show-temporary';
    }
  } else if (clickIndex % 4 === 2) {
    if (
      currentClasses.includes('menu-default') &&
      currentClasses.includes('menu-sub-hidden')
    ) {
      nextClasses = 'menu-default menu-sub-hidden sub-hidden';
    } else if (currentClasses.includes('menu-default')) {
      nextClasses = 'menu-default main-hidden sub-hidden';
    } else if (currentClasses.includes('menu-sub-hidden')) {
      nextClasses = 'menu-sub-hidden sub-hidden';
    } else if (currentClasses.includes('menu-hidden')) {
      nextClasses = 'menu-hidden main-show-temporary sub-show-temporary';
    }
  } else if (clickIndex % 4 === 3) {
    if (
      currentClasses.includes('menu-default') &&
      currentClasses.includes('menu-sub-hidden')
    ) {
      nextClasses = 'menu-default menu-sub-hidden sub-show-temporary';
    } else if (currentClasses.includes('menu-default')) {
      nextClasses = 'menu-default sub-hidden';
    } else if (currentClasses.includes('menu-sub-hidden')) {
      nextClasses = 'menu-sub-hidden sub-show-temporary';
    } else if (currentClasses.includes('menu-hidden')) {
      nextClasses = 'menu-hidden main-show-temporary';
    }
  }
  if (currentClasses.includes('menu-mobile')) {
    nextClasses += ' menu-mobile';
  }
  return {
    type: ActionTypes.MENU_SET_CLASSNAMES,
    payload: { containerClassnames: nextClasses, menuClickCount: clickIndex },
  };
};

// export const getUnits = (): Thunk => async (dispatch, getState, context) => {
//   const activityId = uuidv4();

//   try {
//     await dispatch(
//       beginActivity({
//         type: ActionTypes.GET_UNITS,
//         uuid: activityId,
//       })
//     );

//     const units = await context.api.data.map().getUnits();
//     if (units) {
//       dispatch(getUnitsAction(units));
//     }

//     // dispatch local store action
//   } catch (error) {
//     await dispatch(
//       setError({
//         type: ActionTypes.GET_UNITS,
//         error: error.message,
//         uuid: uuidv4(),
//       })
//     );
//   } finally {
//     await dispatch(endActivity({ uuid: activityId }));
//   }
// };

// export const changeSelectedMenuHasSubItems = (payload) => {
//     return {
//       type: MENU_CHANGE_HAS_SUB_ITEM_STATUS,
//       payload,
//     };
//   };

//   export const changeDefaultClassnames = (strCurrentClasses) => {
//     return {
//       type: MENU_CHANGE_DEFAULT_CLASSES,
//       payload: strCurrentClasses,
//     };
//   };

//   export const addContainerClassname = (classname, strCurrentClasses) => {
//     const newClasses =
//       !strCurrentClasses.indexOf(classname) > -1
//         ? `${strCurrentClasses} ${classname}`
//         : strCurrentClasses;
//     return {
//       type: MENU_CONTAINER_ADD_CLASSNAME,
//       payload: newClasses,
//     };
//   };

// export const clickOnMobileMenu = (strCurrentClasses) => {
//   const currentClasses = strCurrentClasses
//     ? strCurrentClasses
//         .split(' ')
//         .filter((x) => x !== '' && x !== 'sub-show-temporary')
//     : '';
//   let nextClasses = '';
//   if (currentClasses.includes('main-show-temporary')) {
//     nextClasses = currentClasses
//       .filter((x) => x !== 'main-show-temporary')
//       .join(' ');
//   } else {
//     nextClasses = `${currentClasses.join(' ')} main-show-temporary`;
//   }
//   return {
//     type: MENU_CLICK_MOBILE_MENU,
//     payload: { containerClassnames: nextClasses, menuClickCount: 0 },
//   };
// };

// export const setContainerClassnames = (
//   clickIndex,
//   strCurrentClasses,
//   selectedMenuHasSubItems
// ) => {
//   const currentClasses = strCurrentClasses
//     ? strCurrentClasses.split(' ').filter((x) => x !== '')
//     : '';
//   let nextClasses = '';
//   if (!selectedMenuHasSubItems) {
//     if (
//       currentClasses.includes('menu-default') &&
//       (clickIndex % 4 === 0 || clickIndex % 4 === 3)
//     ) {
//       clickIndex = 1;
//     }
//     if (currentClasses.includes('menu-sub-hidden') && clickIndex % 4 === 2) {
//       clickIndex = 0;
//     }
//     if (
//       currentClasses.includes('menu-hidden') &&
//       (clickIndex % 4 === 2 || clickIndex % 4 === 3)
//     ) {
//       clickIndex = 0;
//     }
//   }

//   if (clickIndex % 4 === 0) {
//     if (
//       currentClasses.includes('menu-default') &&
//       currentClasses.includes('menu-sub-hidden')
//     ) {
//       nextClasses = 'menu-default menu-sub-hidden';
//     } else if (currentClasses.includes('menu-default')) {
//       nextClasses = 'menu-default';
//     } else if (currentClasses.includes('menu-sub-hidden')) {
//       nextClasses = 'menu-sub-hidden';
//     } else if (currentClasses.includes('menu-hidden')) {
//       nextClasses = 'menu-hidden';
//     }
//     clickIndex = 0;
//   } else if (clickIndex % 4 === 1) {
//     if (
//       currentClasses.includes('menu-default') &&
//       currentClasses.includes('menu-sub-hidden')
//     ) {
//       nextClasses = 'menu-default menu-sub-hidden main-hidden sub-hidden';
//     } else if (currentClasses.includes('menu-default')) {
//       nextClasses = 'menu-default sub-hidden';
//     } else if (currentClasses.includes('menu-sub-hidden')) {
//       nextClasses = 'menu-sub-hidden main-hidden sub-hidden';
//     } else if (currentClasses.includes('menu-hidden')) {
//       nextClasses = 'menu-hidden main-show-temporary';
//     }
//   } else if (clickIndex % 4 === 2) {
//     if (
//       currentClasses.includes('menu-default') &&
//       currentClasses.includes('menu-sub-hidden')
//     ) {
//       nextClasses = 'menu-default menu-sub-hidden sub-hidden';
//     } else if (currentClasses.includes('menu-default')) {
//       nextClasses = 'menu-default main-hidden sub-hidden';
//     } else if (currentClasses.includes('menu-sub-hidden')) {
//       nextClasses = 'menu-sub-hidden sub-hidden';
//     } else if (currentClasses.includes('menu-hidden')) {
//       nextClasses = 'menu-hidden main-show-temporary sub-show-temporary';
//     }
//   } else if (clickIndex % 4 === 3) {
//     if (
//       currentClasses.includes('menu-default') &&
//       currentClasses.includes('menu-sub-hidden')
//     ) {
//       nextClasses = 'menu-default menu-sub-hidden sub-show-temporary';
//     } else if (currentClasses.includes('menu-default')) {
//       nextClasses = 'menu-default sub-hidden';
//     } else if (currentClasses.includes('menu-sub-hidden')) {
//       nextClasses = 'menu-sub-hidden sub-show-temporary';
//     } else if (currentClasses.includes('menu-hidden')) {
//       nextClasses = 'menu-hidden main-show-temporary';
//     }
//   }
//   if (currentClasses.includes('menu-mobile')) {
//     nextClasses += ' menu-mobile';
//   }
//   return {
//     type: MENU_SET_CLASSNAMES,
//     payload: { containerClassnames: nextClasses, menuClickCount: clickIndex },
//   };
// };
