import {
  defaultMenuType,
  menuHiddenBreakpoint,
  subHiddenBreakpoint,
} from 'constants/defaultValues';
import { State } from './types';

const initialState: State = {
  containerClassnames: defaultMenuType,
  selectedMenuHasSubItems: false, // if you use menu-sub-hidden as default menu type, set value of this variable to false
  subHiddenBreakpoint: subHiddenBreakpoint,
  menuHiddenBreakpoint: menuHiddenBreakpoint,
  menuClickCount: 0,
};

export default initialState;
