import {
  BasicUserInformation,
  DemandInformation,
  Demands,
  ExtendedDemandInformation,
  ExtendedOfferInformation,
  ExtendedStockInformation,
  OfferInformation,
  StockInformation,
  UserInformation,
} from 'api/account';
import {
  Cart,
  Category,
  GetCartResponse,
  Order,
  Producers,
  Unit,
} from 'api/map';
import produce from 'immer';
import { ActionCreator } from 'redux';
import { getAllDemands, getProducers } from 'store/map';
import { beginActivity, endActivity, setError, setSnack } from 'store/request';
import { Thunk } from 'store/types';
import { v4 as uuidv4 } from 'uuid';
import {
  ActionTypes,
  DeleteOffer,
  DeleteStock,
  EmptyCartAction,
  GetCartAction,
  GetCartActionAnonymous,
  GetDemands,
  GetUserInformation,
  InsertCartAction,
  InsertOfferAction,
  InsertStockAction,
  LoginAction,
  PlaceOrderAction,
  SignOutAction,
  SignUpAction,
  UpdateDemandInformation,
  UpdateOfferInformation,
  UpdateStockInformation,
  UpdateUserInformation,
} from './types';

export const loginAction: ActionCreator<LoginAction> = (
  data: BasicUserInformation,
  token: string
) => ({
  type: ActionTypes.LOGIN,
  payload: {
    data,
    token,
  },
});

export const login = (data: BasicUserInformation): Thunk => async (
  dispatch,
  getState,
  context
) => {
  const activityId = uuidv4();
  let errorExists: boolean = false;
  try {
    await dispatch(
      beginActivity({
        type: ActionTypes.LOGIN,
        uuid: activityId,
      })
    );

    const { token } = await context.api.data.account().login(data);

    let anonCart = localStorage.getItem('cart');
    let parsed = anonCart && JSON.parse(anonCart);
    let forInsert = { products: [{}] };

    localStorage.setItem('token', token);
    if (token) {
      dispatch(loginAction(data, token));
      const userInformation = await context.api.data
        .account()
        .getUserInformation(token);
      dispatch(getUserInformationAction(token, userInformation));

      if (parsed) {
        await context.api.data.account().emptyCart(token);

        forInsert.products.shift();
        parsed.map(({ products }) => {
          return products.forEach(({ id, quantity }) => {
            forInsert.products.push({ id, quantity });
          });
        });

        await context.api.data.account().insertCart(token, forInsert);
        localStorage.removeItem('cart');
        dispatch(getCart());
      }
    }
  } catch (error) {
    dispatch(signOut());
    await dispatch(
      setError({
        type: ActionTypes.LOGIN,
        error: error.message,
        uuid: uuidv4(),
      })
    );
    errorExists = true;
    await dispatch(
      setSnack({
        type: 'error',
        message: 'Emailul sau parola nu sunt corecte.',
        status: 500,
      })
    );
  } finally {
    await dispatch(endActivity({ uuid: activityId }));
    if (!errorExists)
      await dispatch(
        setSnack({
          type: 'success',
          message: 'Te-ai logat cu success!',
          status: 200,
        })
      );
  }
};

export const getUserInformationAction: ActionCreator<GetUserInformation> = (
  token: string,
  data: Producers[]
) => ({
  type: ActionTypes.GET_USER_INFORMATION,
  payload: {
    token,
    data,
  },
});

export const getDemands: ActionCreator<GetDemands> = (
  token: string,
  data: Demands[]
) => ({
  type: ActionTypes.GET_DEMANDS,
  payload: {
    token,
    data,
  },
});

export const getUserInformation = (): Thunk => async (
  dispatch,
  getState,
  context
) => {
  const activityId = uuidv4();
  try {
    await dispatch(
      beginActivity({
        type: ActionTypes.GET_USER_INFORMATION,
        uuid: activityId,
      })
    );

    const token = localStorage.getItem('token');
    if (token) {
      const userInformation = await context.api.data
        .account()
        .getUserInformation(token);
      if (!userInformation[0].latitude || !userInformation[0].longitude) {
        userInformation[0].latitude = 45.8266751301338;
        userInformation[0].longitude = 25.13688627812501;
      }
      if (!userInformation[0] || !userInformation[0].id)
        throw new Error('getUserInfomation info or id null!');
      dispatch(getUserInformationAction(token, userInformation));
    }
  } catch (error) {
    dispatch(signOut());
    await dispatch(
      setError({
        type: ActionTypes.GET_USER_INFORMATION,
        error: error.message,
        uuid: uuidv4(),
      })
    );
    await dispatch(
      setSnack({
        type: 'error',
        message: error.response
          ? error.response.statusText
          : 'An unexpected error occured',
        status: error.response ? error.response.status : 500,
      })
    );
  } finally {
    await dispatch(endActivity({ uuid: activityId }));
  }
};

export const insertStockAction: ActionCreator<InsertStockAction> = (
  data: StockInformation,
  image: FormData
) => ({
  type: ActionTypes.INSERT_STOCK,
  payload: {
    data,
    image,
  },
});

export const insertStock = (
  data: StockInformation,
  image?: FormData,
  updateUserFirst?: Partial<UserInformation>
): Thunk => async (dispatch, getState, context) => {
  const activityId = uuidv4();
  let errorExists: boolean = false;
  try {
    await dispatch(
      beginActivity({
        type: ActionTypes.INSERT_STOCK,
        uuid: activityId,
      })
    );

    const token = localStorage.getItem('token');

    const categories = getState().data.map.categories;
    const measurements = getState().data.map.units;
    if (token) {
      let userId = getState().data.account.user.id;
      if (updateUserFirst) {
        await context.api.data
          .account()
          .setUserInformation(token, updateUserFirst);
        const get = await context.api.data.account().getUserInformation(token);
        userId = get[0].id;
        dispatch(setUserInformationAction(token, get));
      }

      if (userId) {
        const stock = await context.api.data
          .account()
          .insertStock(userId, token, data);
        if (stock.id) {
          if (image) {
            const finalStock = await context.api.data
              .account()
              .insertImageStock(stock.id, token, image);
            dispatch(
              setStockInformationAction(
                stock.id,
                finalStock,
                categories,
                measurements
              )
            );
          } else {
            dispatch(
              setStockInformationAction(
                stock.id,
                stock,
                categories,
                measurements
              )
            );
          }
        }
      } else {
        errorExists = true;
      }

      // dispatch(getUserInformationAction(token, userInformation));
    }
  } catch (error) {
    await dispatch(
      setError({
        type: ActionTypes.INSERT_STOCK,
        error: error.message,
        uuid: uuidv4(),
      })
    );
    errorExists = true;
    await dispatch(
      setSnack({
        type: 'error',
        message: error.response
          ? error.response.statusText
          : 'An unexpected error occured',
        status: error.response ? error.response.status : 500,
      })
    );
  } finally {
    await dispatch(endActivity({ uuid: activityId }));

    if (!errorExists)
      await dispatch(
        setSnack({
          type: 'success',
          message: 'Produsul a fost adaugat cu success!',
          status: 200,
        })
      );
    await dispatch(getProducers());
  }
};

export const insertProfilePhoto = (image: FormData): Thunk => async (
  dispatch,
  getState,
  context
) => {
  const activityId = uuidv4();
  let errorExists: boolean = false;
  try {
    await dispatch(
      beginActivity({
        type: ActionTypes.INSERT_PROFILE_PHOTO,
        uuid: activityId,
      })
    );

    const token = localStorage.getItem('token');
    if (token) {
      await context.api.data.account().insertProfilePhoto(token, image);
    }
  } catch (error) {
    await dispatch(
      setError({
        type: ActionTypes.INSERT_PROFILE_PHOTO,
        error: error.message,
        uuid: uuidv4(),
      })
    );
    errorExists = true;
    await dispatch(
      setSnack({
        type: 'error',
        message: error.response
          ? error.response.statusText
          : 'An unexpected error occured',
        status: error.response ? error.response.status : 500,
      })
    );
  } finally {
    await dispatch(endActivity({ uuid: activityId }));

    if (!errorExists) window.location.reload();
  }
};

export const deleteStockAction: ActionCreator<DeleteStock> = (id: number) => ({
  type: ActionTypes.DELETE_STOCK,
  payload: {
    id,
  },
});

export const deleteStock = (id: number): Thunk => async (
  dispatch,
  getState,
  context
) => {
  const activityId = uuidv4();
  let errorExists: boolean = false;
  try {
    await dispatch(
      beginActivity({
        type: ActionTypes.DELETE_STOCK,
        uuid: activityId,
      })
    );

    const token = localStorage.getItem('token');

    if (token && id) {
      const string = await context.api.data.account().deleteStock(id, token);
      if (string) {
        dispatch(deleteStockAction(id));
      }
    }
  } catch (error) {
    await dispatch(
      setError({
        type: ActionTypes.DELETE_STOCK,
        error: error.message,
        uuid: uuidv4(),
      })
    );
    errorExists = true;
    await dispatch(
      setSnack({
        type: 'error',
        message: error.response
          ? error.response.statusText
          : 'An unexpected error occured',
        status: error.response ? error.response.status : 500,
      })
    );
  } finally {
    await dispatch(endActivity({ uuid: activityId }));
    if (!errorExists)
      await dispatch(
        setSnack({
          type: 'success',
          message: 'Produsul a fost sters!',
          status: 200,
        })
      );
  }
};

export const deleteOfferAction: ActionCreator<DeleteOffer> = (id: number) => ({
  type: ActionTypes.DELETE_OFFER,
  payload: {
    id,
  },
});

export const deleteOffer = (id: number): Thunk => async (
  dispatch,
  getState,
  context
) => {
  const activityId = uuidv4();
  let errorExists: boolean = false;
  try {
    await dispatch(
      beginActivity({
        type: ActionTypes.DELETE_OFFER,
        uuid: activityId,
      })
    );

    const token = localStorage.getItem('token');

    if (token && id) {
      const string = await context.api.data.account().deleteOffer(id, token);
      if (string) {
        dispatch(deleteOfferAction(id));
      }
    }
  } catch (error) {
    await dispatch(
      setError({
        type: ActionTypes.DELETE_OFFER,
        error: error.message,
        uuid: uuidv4(),
      })
    );
    errorExists = true;
    await dispatch(
      setSnack({
        type: 'error',
        message: error.response
          ? error.response.statusText
          : 'An unexpected error occured',
        status: error.response ? error.response.status : 500,
      })
    );
  } finally {
    await dispatch(endActivity({ uuid: activityId }));
    if (!errorExists)
      await dispatch(
        setSnack({
          type: 'success',
          message: 'Produsul a fost sters!',
          status: 200,
        })
      );
  }
};

export const setUserInformationAction: ActionCreator<UpdateUserInformation> = (
  token: string,
  data: Partial<UserInformation>
) => ({
  type: ActionTypes.UPDATE_USER_INFORMATION,
  payload: {
    token,
    data,
  },
});

export const setUserInformation = (
  data: Partial<UserInformation>
): Thunk => async (dispatch, getState, context) => {
  const activityId = uuidv4();
  let errorExists: boolean = false;

  try {
    await dispatch(
      beginActivity({
        type: ActionTypes.UPDATE_USER_INFORMATION,
        uuid: activityId,
      })
    );

    const token = localStorage.getItem('token');
    if (token) {
      const deliveryInfos = data.DeliveryInfos;
      delete data.DeliveryInfos;

      await context.api.data.account().setUserInformation(token, data);
      const get: Partial<
        Producers
      >[] = await context.api.data.account().getUserInformation(token);
      get[0].DeliveryInfos = deliveryInfos;
      dispatch(setUserInformationAction(token, get[0]));
    }
  } catch (error) {
    await dispatch(
      setError({
        type: ActionTypes.UPDATE_USER_INFORMATION,
        error: error.message,
        uuid: uuidv4(),
      })
    );
    await dispatch(
      setSnack({
        type: 'error',
        message: error.response.data.message
          ? error.response.data.message
          : 'An unexpected error occured',
        status: error.response ? error.response.status : 500,
      })
    );
    errorExists = true;
  } finally {
    await dispatch(endActivity({ uuid: activityId }));
    if (!errorExists)
      await dispatch(
        setSnack({
          type: 'success',
          message: 'Datele au fost salvate!',
          status: 200,
        })
      );
  }
};

export const registerAction: ActionCreator<SignUpAction> = (
  data: UserInformation,
  token: string
) => ({
  type: ActionTypes.SIGN_UP,
  payload: {
    data,
    token,
  },
});

export const register = (data: UserInformation): Thunk => async (
  dispatch,
  getState,
  context
) => {
  const activityId = uuidv4();
  let errorExists: boolean = false;
  try {
    await dispatch(
      beginActivity({
        type: ActionTypes.SIGN_UP,
        uuid: activityId,
      })
    );

    let token = '';
    const registered = await context.api.data.account().register(data);
    let anonCart = localStorage.getItem('cart');
    let parsed = anonCart && JSON.parse(anonCart);
    let forInsert = { products: [{}] };

    if (registered) {
      token = (
        await context.api.data
          .account()
          .login({ email: data.email, password: data.password })
      ).token;
    }

    localStorage.setItem('token', token);
    if (token) {
      dispatch(registerAction(data, token));

      if (parsed) {
        await context.api.data.account().emptyCart(token);

        forInsert.products.shift();
        parsed.map(({ products }) => {
          return products.forEach(({ id, quantity }) => {
            forInsert.products.push({ id, quantity });
          });
        });

        await context.api.data.account().insertCart(token, forInsert);
        localStorage.removeItem('cart');
        dispatch(getCart());
      }
    }
  } catch (error) {
    await dispatch(
      setError({
        type: ActionTypes.SIGN_UP,
        error: error.message,
        uuid: uuidv4(),
        timeout: 500,
      })
    );
    errorExists = true;

    await dispatch(
      setSnack({
        type: 'error',
        message: error.response
          ? error.response.data.message
          : 'An unexpected error occured',
        status: error.response ? error.response.status : 500,
      })
    );
  } finally {
    await dispatch(endActivity({ uuid: activityId }));
    if (!errorExists)
      await dispatch(
        setSnack({
          type: 'success',
          message: 'Contul tău a fost creat!',
          status: 200,
        })
      );
  }
};

export const signoutAction: ActionCreator<SignOutAction> = () => ({
  type: ActionTypes.SIGN_OUT,
});

export const signOut = (): Thunk => async (dispatch, getState, context) => {
  const activityId = uuidv4();

  try {
    await dispatch(
      beginActivity({
        type: ActionTypes.SIGN_OUT,
        uuid: activityId,
      })
    );
    localStorage.removeItem('token');
    localStorage.removeItem('affiliateToCode');
    localStorage.removeItem('communityAuthCode');
    localStorage.removeItem('promoterCode');
    localStorage.removeItem('delivery');
    localStorage.removeItem('subTotal');
    localStorage.removeItem('redirectOption');

    dispatch(signoutAction());
    dispatch(emptyCartAction());
  } catch (error) {
    await dispatch(
      setError({
        type: ActionTypes.SIGN_OUT,
        error: error.message,
        uuid: uuidv4(),
      })
    );
    await dispatch(
      setSnack({ type: 'error', message: error.message, status: error.status })
    );
    await dispatch(
      setSnack({
        type: 'error',
        message: error.response
          ? error.response.statusText
          : 'An unexpected error occured',
        status: error.response ? error.response.status : 500,
      })
    );
  } finally {
    await dispatch(endActivity({ uuid: activityId }));
  }
};

export const setDemandInformationAction: ActionCreator<UpdateDemandInformation> = (
  id: number,
  data: ExtendedDemandInformation,
  categories: Category[]
) => ({
  type: ActionTypes.UPDATE_DEMAND_INFORMATION,
  payload: {
    id,
    data,
    categories,
  },
});

export const setTheDemandInformation = (
  id: number,
  data: Partial<DemandInformation>
): Thunk => async (dispatch, getState, context) => {
  const activityId = uuidv4();
  let errorExists: boolean = false;
  try {
    await dispatch(
      beginActivity({
        type: ActionTypes.UPDATE_DEMAND_INFORMATION,
        uuid: activityId,
      })
    );

    const token = localStorage.getItem('token');
    if (token && id) {
      await context.api.data.account().setDemandInformation(id, token, data);
      const categories = getState().data.map.categories;

      dispatch(setDemandInformationAction(id, data, categories));
    }
  } catch (error) {
    errorExists = true;
    await dispatch(
      setError({
        type: ActionTypes.UPDATE_DEMAND_INFORMATION,
        error: error.message,
        uuid: uuidv4(),
      })
    );
    await dispatch(
      setSnack({
        type: 'error',
        message: error.response
          ? error.response.statusText
          : 'An unexpected error occured',
        status: error.response ? error.response.status : 500,
      })
    );
  } finally {
    await dispatch(endActivity({ uuid: activityId }));
    if (!errorExists)
      await dispatch(
        setSnack({
          type: 'success',
          message: 'Cererea a fost modificată cu success!',
          status: 200,
        })
      );
    // await dispatch(getDemands( token, demands));
  }
};

export const setStockInformationAction: ActionCreator<UpdateStockInformation> = (
  id: number,
  data: ExtendedStockInformation,
  categories: Category[],
  measurements: Unit[]
) => ({
  type: ActionTypes.UPDATE_STOCK_INFORMATION,
  payload: {
    id,
    data,
    categories,
    measurements,
  },
});

export const setStockInformation = (
  id: number,
  data: Partial<StockInformation>,
  image?: FormData
): Thunk => async (dispatch, getState, context) => {
  const activityId = uuidv4();
  let errorExists: boolean = false;
  try {
    await dispatch(
      beginActivity({
        type: ActionTypes.UPDATE_STOCK_INFORMATION,
        uuid: activityId,
      })
    );

    const token = localStorage.getItem('token');
    if (token && id) {
      const stock = await context.api.data
        .account()
        .setStockInformation(id, token, data);
      const categories = getState().data.map.categories;
      const measurements = getState().data.map.units;
      if (image) {
        const finalStock = await context.api.data
          .account()
          .insertImageStock(id, token, image);
        dispatch(
          setStockInformationAction(id, finalStock, categories, measurements)
        );
      } else {
        dispatch(
          setStockInformationAction(id, stock, categories, measurements)
        );
      }
    }
  } catch (error) {
    errorExists = true;
    await dispatch(
      setError({
        type: ActionTypes.UPDATE_STOCK_INFORMATION,
        error: error.message,
        uuid: uuidv4(),
      })
    );
    await dispatch(
      setSnack({
        type: 'error',
        message: error.response
          ? error.response.statusText
          : 'An unexpected error occured',
        status: error.response ? error.response.status : 500,
      })
    );
  } finally {
    await dispatch(endActivity({ uuid: activityId }));
    if (!errorExists)
      await dispatch(
        setSnack({
          type: 'success',
          message: 'Produsul a fost modificat cu success!',
          status: 200,
        })
      );
    await dispatch(getProducers());
  }
};

// update offer
export const setOfferInformationAction: ActionCreator<UpdateOfferInformation> = (
  id: number,
  data: ExtendedOfferInformation
) => ({
  type: ActionTypes.UPDATE_OFFER_INFORMATION,
  payload: {
    id,
    data,
  },
});

export const setOfferInformation = (
  id: number,
  data: Partial<OfferInformation>
): Thunk => async (dispatch, getState, context) => {
  const activityId = uuidv4();
  let errorExists: boolean = false;
  try {
    await dispatch(
      beginActivity({
        type: ActionTypes.UPDATE_OFFER_INFORMATION,
        uuid: activityId,
      })
    );

    const token = localStorage.getItem('token');
    if (token && id) {
      await context.api.data.account().setOfferInformation(id, token, data);
    }
  } catch (error) {
    errorExists = true;
    await dispatch(
      setError({
        type: ActionTypes.UPDATE_OFFER_INFORMATION,
        error: error.message,
        uuid: uuidv4(),
      })
    );
    await dispatch(
      setSnack({
        type: 'error',
        message: error.response
          ? error.response.statusText
          : 'An unexpected error occured',
        status: error.response ? error.response.status : 500,
      })
    );
  } finally {
    await dispatch(endActivity({ uuid: activityId }));
    if (!errorExists)
      await dispatch(
        setSnack({
          type: 'success',
          message: 'Oferta a fost modificată cu success!',
          status: 200,
        })
      );
    // await dispatch(getProducers());
  }
};

// add new offer
export const insertOfferAction: ActionCreator<InsertOfferAction> = (
  data: OfferInformation
) => ({
  type: ActionTypes.INSERT_OFFER,
  payload: {
    data,
    //aray
  },
});

export const insertOffer = (data: OfferInformation): Thunk => async (
  dispatch,
  getState,
  context
) => {
  const activityId = uuidv4();
  let errorExists: boolean = false;
  try {
    await dispatch(
      beginActivity({
        type: ActionTypes.INSERT_OFFER,
        uuid: activityId,
      })
    );

    const token = localStorage.getItem('token');

    if (token) {
      await context.api.data.account().insertOffer(token, data);
    }
  } catch (error) {
    await dispatch(
      setError({
        type: ActionTypes.INSERT_OFFER,
        error: error.message,
        uuid: uuidv4(),
      })
    );
    errorExists = true;
    await dispatch(
      setSnack({
        type: 'error',
        message: error.response
          ? error.response.statusText
          : 'An unexpected error occured',
        status: error.response ? error.response.status : 500,
      })
    );
  } finally {
    await dispatch(endActivity({ uuid: activityId }));

    if (!errorExists)
      await dispatch(
        setSnack({
          type: 'success',
          message: 'Oferta a fost adaugat cu success!',
          status: 200,
        })
      );
    // await dispatch(getProducers());
  }
};

export const insertDemand = (data: DemandInformation): Thunk => async (
  dispatch,
  getState,
  context
) => {
  const activityId = uuidv4();
  let errorExists: boolean = false;
  try {
    await dispatch(
      beginActivity({
        type: ActionTypes.INSERT_DEMAND,
        uuid: activityId,
      })
    );

    const token = localStorage.getItem('token');
    // const userId = getState().data.account.user.id;

    if (token) {
      const demands = await context.api.data
        .account()
        .insertDemand(token, data);
      //raspunsul trebuie pus in Redux
      dispatch(getDemands(token, demands));
      dispatch(getAllDemands());
    }
  } catch (error) {
    await dispatch(
      setError({
        type: ActionTypes.INSERT_DEMAND,
        error: error.message,
        uuid: uuidv4(),
      })
    );
    errorExists = true;
    await dispatch(
      setSnack({
        type: 'error',
        message: error.response
          ? error.response.statusText
          : 'An unexpected error occured',
        status: error.response ? error.response.status : 500,
      })
    );
  } finally {
    await dispatch(endActivity({ uuid: activityId }));

    if (!errorExists)
      await dispatch(
        setSnack({
          type: 'success',
          message: 'Cererea a fost adaugată cu success!',
          status: 200,
        })
      );
    // await dispatch(getProducers());
  }
};

// add new order
export const insertOrderAction: ActionCreator<PlaceOrderAction> = (
  data: Order
) => ({
  type: ActionTypes.PLACE_ORDER,
  payload: {
    data,
  },
});

export const emptyCartAction: ActionCreator<EmptyCartAction> = () => ({
  type: ActionTypes.EMPTY_CART,
});

export const insertOrder = (data: Order): Thunk => async (
  dispatch,
  getState,
  context
) => {
  const activityId = uuidv4();
  let errorExists: boolean = false;
  try {
    await dispatch(
      beginActivity({
        type: ActionTypes.PLACE_ORDER,
        uuid: activityId,
      })
    );

    const token = localStorage.getItem('token');

    if (token) {
      await context.api.data.account().insertOrder(token, data);
      localStorage.removeItem('subTotal');
      localStorage.removeItem('delivery');
      localStorage.removeItem('redirectOption');
      dispatch(emptyCartAction());
    }
  } catch (error) {
    await dispatch(
      setError({
        type: ActionTypes.PLACE_ORDER,
        error: error.response,
        uuid: uuidv4(),
      })
    );
    errorExists = true;
    await dispatch(
      setSnack({
        type: 'error',
        message: error.response
          ? error.response.data.message
          : 'An unexpected error occured',
        status: error.response ? error.response.status : 500,
      })
    );
  } finally {
    await dispatch(endActivity({ uuid: activityId }));

    if (!errorExists)
      await dispatch(
        setSnack({
          type: 'success',
          message: 'Comanda a fost plasată!',
          status: 200,
        })
      );
    // await dispatch(getProducers());
  }
};

// insertCart
export const insertCartAction: ActionCreator<InsertCartAction> = (
  data: Cart
) => ({
  type: ActionTypes.INSERT_CART,
  payload: {
    data,
  },
});

export const insertCart = (data: Cart, fromCart: boolean): Thunk => async (
  dispatch,
  getState,
  context
) => {
  const activityId = uuidv4();
  let errorExists: boolean = false;
  try {
    await dispatch(
      beginActivity({
        type: ActionTypes.INSERT_CART,
        uuid: activityId,
      })
    );

    const token = localStorage.getItem('token');

    if (token) {
      await context.api.data.account().insertCart(token, data);
      await dispatch(getCart());
    } else {
      const cart = localStorage.getItem('cart');
      if (cart) {
        let cartArray = JSON.parse(cart);
        if (!data.userId) {
          let productDelete = -1;
          let productProducer = -1;
          let producerDelete = -1;
          cartArray.forEach(({ products }, index) => {
            products.forEach((product, indexProduct) => {
              if (product.id === data.products[0].id) {
                if (product.quantity + data.products[0].quantity <= 0) {
                  if (products.length === 1) {
                    producerDelete = index;
                  }
                  productDelete = indexProduct;
                  productProducer = index;
                } else {
                  product.quantity =
                    product.quantity + data.products[0].quantity;
                }
              }
            });
          });

          if (productDelete > -1) {
            cartArray[productProducer].products.splice(productDelete, 1);
          }
          if (producerDelete > -1) {
            cartArray.splice(producerDelete, 1);
          }

          if (cartArray.length === 0) {
            localStorage.removeItem('cart');
            dispatch(getCart());
          } else {
            localStorage.setItem('cart', JSON.stringify(cartArray));
            dispatch(getCart());
          }
        } else {
          cartArray = produce(cartArray, (draft) => {
            const foundSeller = draft.findIndex(
              (seller) => seller.userId === data.userId
            );

            if (foundSeller !== -1) {
              const foundProduct = draft[foundSeller].products.findIndex(
                (product) => product.id === data.products[0].id
              );
              if (foundProduct !== -1) {
                draft[foundSeller].products[foundProduct].quantity +=
                  data.products[0].quantity;
              } else {
                draft[foundSeller].products.push(data.products[0]);
              }
            } else {
              draft.push(data);
            }
          });
          localStorage.setItem('cart', JSON.stringify(cartArray));
          dispatch(getCart());
        }
      } else {
        const array = [data];
        localStorage.setItem('cart', JSON.stringify(array));
        dispatch(getCart());
      }
    }
  } catch (error) {
    await dispatch(
      setError({
        type: ActionTypes.INSERT_CART,
        error: error.message,
        uuid: uuidv4(),
      })
    );
    errorExists = true;
    await dispatch(
      setSnack({
        type: 'error',
        message: error.response
          ? error.response.statusText
          : error.message
          ? error.message
          : 'An unexpected error occured',
        status: error.response ? error.response.status : 500,
      })
    );
  } finally {
    await dispatch(endActivity({ uuid: activityId }));

    if (!errorExists) {
      if (data.products[0].quantity < 0) {
        if (!fromCart) {
          await dispatch(
            setSnack({
              type: 'success',
              message: 'Produsul a fost șters din coș',
              status: 200,
            })
          );
        }
      } else {
        if (!fromCart) {
          await dispatch(
            setSnack({
              type: 'success',
              message: 'Produsul a fost adăugat în coș!',
              status: 200,
            })
          );
        }
      }
    } else {
      dispatch(signoutAction());
    }
  }
};

// Get Cart
export const getCartAction: ActionCreator<GetCartAction> = (
  data: GetCartResponse
) => ({
  type: ActionTypes.GET_CART,
  payload: {
    data,
  },
});

export const getCartActionAnonymous: ActionCreator<GetCartActionAnonymous> = (
  data: Cart[]
) => ({
  type: ActionTypes.GET_CART_ANONYMOUS,
  payload: {
    data,
  },
});

export const getDemand = (): Thunk => async (dispatch, getState, context) => {
  const activityId = uuidv4();

  try {
    await dispatch(
      beginActivity({
        type: ActionTypes.GET_DEMANDS,
        uuid: activityId,
      })
    );

    const token = localStorage.getItem('token');
    if (token) {
      const id = getState().data.account.user.id;
      if (id) {
        const demands = await context.api.data.account().getDemands(token, id);

        dispatch(getDemands(token, demands));
      }
    }
  } catch (error) {
    await dispatch(
      setError({
        type: ActionTypes.GET_DEMANDS,
        error: error.message,
        uuid: uuidv4(),
      })
    );
    await dispatch(
      setSnack({
        type: 'error',
        message: error.response
          ? error.response.statusText
          : 'An unexpected error occured',
        status: error.response ? error.response.status : 500,
      })
    );
  } finally {
    await dispatch(endActivity({ uuid: activityId }));
  }
};

export const getCart = (): Thunk => async (dispatch, getState, context) => {
  const activityId = uuidv4();

  try {
    await dispatch(
      beginActivity({
        type: ActionTypes.GET_CART,
        uuid: activityId,
      })
    );

    const token = localStorage.getItem('token');
    if (token) {
      const cart = await context.api.data.account().getCart(token);
      dispatch(getCartAction(cart));
    } else {
      const cart = localStorage.getItem('cart');
      if (cart) {
        dispatch(getCartActionAnonymous(JSON.parse(cart)));
      } else {
        dispatch(getCartActionAnonymous([]));
      }
    }
  } catch (error) {
    await dispatch(
      setError({
        type: ActionTypes.GET_CART,
        error: error.message,
        uuid: uuidv4(),
      })
    );
    await dispatch(
      setSnack({
        type: 'error',
        message: error.response
          ? error.response.statusText
          : 'An unexpected error occured',
        status: error.response ? error.response.status : 500,
      })
    );
  } finally {
    await dispatch(endActivity({ uuid: activityId }));
  }
};
