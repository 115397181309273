import { sendGetRequest } from 'api/utils';
import { api_url_strapi } from '../utils/consts';
import {
  GetPagesResponse,
  GetPartnersResponse,
  GetTermsAndConditionsResponse,
  Pages,
  PartnersData,
  TermsAndConditions,
} from './types';

export default () => ({
  getPartners: async (): Promise<PartnersData> => {
    const { data }: GetPartnersResponse = await sendGetRequest(
      api_url_strapi + '/partners'
    );
    return data;
  },
  getTermsAndConditions: async (): Promise<TermsAndConditions> => {
    const { data }: GetTermsAndConditionsResponse = await sendGetRequest(
      api_url_strapi + '/terms-and-conditions'
    );
    return data;
  },
  getAllPages: async (): Promise<Pages> => {
    const { data }: GetPagesResponse = await sendGetRequest(
      api_url_strapi + '/pages?populate[partners][populate]=*'
    );
    return data;
  },
});
