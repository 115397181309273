import { DEFAULT_ACTIVITY_TIMEOUT } from 'constants/defaultValues';
import { ActionCreator } from 'redux';
import { Thunk } from 'store/types';
import {
  ActionTypes,
  Activity,
  ActivityAction,
  ActivityError,
  ActivityErrorAction,
  SnackAction,
  SnackMessage,
} from './types';

const activityAction: ActionCreator<ActivityAction> = (activity: Activity) => ({
  type: ActionTypes.ACTIVITY,
  payload: activity,
});

const setSnackAction: ActionCreator<SnackAction> = (snack: SnackMessage) => ({
  type: ActionTypes.SET_SNACK,
  payload: snack,
});

export const setSnack = (snack: SnackMessage): Thunk => async (dispatch) => {
  dispatch(setSnackAction(snack));
};

export const beginActivity = (activity: Activity): Thunk => async (
  dispatch
) => {
  dispatch(activityAction(activity));
};

export const endActivity = ({ uuid }: { uuid: string }): Thunk => async (
  dispatch
) => {
  dispatch(activityAction({ uuid, type: null }));
};

const errorAction: ActionCreator<ActivityErrorAction> = (
  error: ActivityError
) => ({
  type: ActionTypes.ERROR,
  payload: error,
});

export const setError = (error: ActivityError): Thunk => async (dispatch) => {
  try {
    dispatch(errorAction(error));
    setTimeout(async () => {
      await dispatch(clearError({ uuid: error.uuid }));
    }, error.timeout || DEFAULT_ACTIVITY_TIMEOUT);
  } catch (e) {
    // Dispatching an error here would most probably
    // create an infinite loop so let's simply log it
    console.error(
      `Awkward... an error occured while dispatching an error ${e}`
    );
  }
};

export const clearError = ({ uuid }: { uuid: string }): Thunk => async (
  dispatch
) => {
  try {
    dispatch(errorAction({ uuid, type: null, error: null }));
  } catch (e) {
    // Dispatching an error here would most probably
    // create an infinite loop so let's simply log it
    console.error(`Awkward... an error occured while clearing an error ${e}`);
  }
};
