import produce from 'immer';
import initialState from './initialState';
import { Actions, ActionTypes, Producer, State } from './types';

let calcCrow = (lat, lng, lat2, lng2) => {
  if (!lat && !lng) {
    return 0;
  }

  var R = 6371; // km
  var dLat = ((lat2 - lat) * Math.PI) / 180;
  var dLon = ((lng2 - lng) * Math.PI) / 180;
  lat = (lat * Math.PI) / 180;
  lat2 = (lat2 * Math.PI) / 180;

  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat) * Math.cos(lat2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return parseFloat(d.toFixed(2));
};

export default (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case ActionTypes.GET_PRODUCERS: {
      const { data: producersRequest, position } = action.payload;
      const producers: Producer = {};

      producersRequest.forEach((producer) => {
        const {
          id,
          firstName,
          lastName,
          alias,
          description,
          latitude,
          longitude,
          phone,
          email,
          deliveryTypeId,
          pictureUrl,
          products,
          affiliateCode,
          ReviewsReceived,
          address,
          DeliveryType,
        } = producer;
        if (id) {
          producers[id] = {
            details: {
              id,
              firstName,
              lastName,
              alias,
              description,
              latitude,
              longitude,
              affiliateCode,
              phone,
              email,
              deliveryTypeId,
              pictureUrl,
              address,
              DeliveryType,
              reviewsReceivedNumber: ReviewsReceived.length,
              reviewsReceivedPoints:
                ReviewsReceived.length === 0
                  ? 0
                  : ReviewsReceived.reduce(function (prev, cur) {
                      return prev + cur.ReviewType.points;
                    }, 0),
              overallRating:
                ReviewsReceived.length > 0
                  ? ReviewsReceived.reduce(function (prev, cur) {
                      return prev + cur.ReviewType.points;
                    }, 0) / ReviewsReceived.length
                  : 0,
              distance: calcCrow(
                latitude,
                longitude,
                position.latitude,
                position.longitude
              ),
            },
            products: products,
          };
        }
      });

      return { ...state, producers: producers, position };
    }

    case ActionTypes.GET_PRODUCTS: {
      const { data, position } = action.payload;
      return produce(state, (draft) => {
        draft.products = data;
        draft.products.forEach((product) => {
          product.User.distance = calcCrow(
            product.User.latitude,
            product.User.longitude,
            position.latitude,
            position.longitude
          );
        });
        draft.position = position;
      });
    }
    case ActionTypes.GET_PRODUCTS_BY_CATEGORY: {
      const { data, position } = action.payload;
      return produce(state, (draft) => {
        draft.productsByCategory = data;
        draft.productsByCategory.forEach((product) => {
          product.User.distance = calcCrow(
            product.User.latitude,
            product.User.longitude,
            position.latitude,
            position.longitude
          );
        });
        draft.position = position;
      });
    }

    case ActionTypes.SET_LIKE_INFORMATION: {
      const {
        likeState,
        demandId,
        id,
        firstName,
        lastName,
        alias,
        pictureUrl,
      } = action.payload;

      const ret = likeState
        ? produce(state, (draft) => {
            draft.demands &&
              draft.demands.forEach((element) => {
                element.id === demandId &&
                  element.likes.push({
                    id: id,
                    firstName: firstName,
                    lastName: lastName,
                    alias: alias,
                    pictureUrl: pictureUrl,
                  });

                element.id === demandId &&
                  element.liked.push({
                    id: id,
                    firstName: firstName,
                    lastName: lastName,
                    alias: alias,
                    pictureUrl: pictureUrl,
                  });
              });
          })
        : produce(state, (draft) => {
            draft.demands &&
              draft.demands.forEach((element) => {
                if (demandId === element.id)
                  element.likes = element.likes.filter(
                    (like) => like.id !== id
                  );
              });
            draft.demands &&
              draft.demands.forEach((element) => {
                if (demandId === element.id) element.liked.pop();
              });
          });

      return ret;
    }

    case ActionTypes.GET_DEMANDS2: {
      const { data } = action.payload;
      return produce(state, (draft) => {
        draft.demands = data;
      });
    }

    case ActionTypes.GET_LABELS: {
      const { data } = action.payload;
      return produce(state, (draft) => {
        draft.labels = data;
      });
    }

    case ActionTypes.GET_ENHANCED_FILTERS: {
      const { data } = action.payload;
      return produce(state, (draft) => {
        draft.enhancedFilters = data;
      });
    }

    case ActionTypes.CLEAR_ENHANCED_FILTERS: {
      return produce(state, (draft) => {
        for (const [key] of Object.entries(draft.enhancedFilters)) {
          draft.enhancedFilters[key].value = null;
        }
      });
    }

    case ActionTypes.SET_ENHANCED_FILTERS: {
      const { data, filterName } = action.payload;
      return produce(state, (draft) => {
        draft.enhancedFilters[filterName].value = data;
      });
    }

    case ActionTypes.GET_COMMUNITY_PRODUCTS: {
      const { data, position } = action.payload;
      return produce(state, (draft) => {
        draft.communityProducts = data;
        draft.communityProducts.forEach((product) => {
          product.User.distance = calcCrow(
            product.User.latitude,
            product.User.longitude,
            position.latitude,
            position.longitude
          );
        });
      });
    }

    case ActionTypes.SET_FILTERS: {
      const newFilters = action.payload.filters;
      return produce(state, (draft) => {
        const { filters } = draft;
        const {
          categories = filters.categories,
          subcategories = filters.subcategories,
          marketCategories = filters.marketCategories,
          lowPrice = filters.lowPrice,
          lowStock = filters.lowStock,
          highPrice = filters.highPrice,
          highStock = filters.highStock,
          lowPriceMarket = filters.lowPriceMarket,
          highPriceMarket = filters.highPriceMarket,
          counties = filters.counties,
          units = filters.units,
          keyword = filters.keyword,
          order = filters.order,
          deliveries = filters.deliveries,
        } = newFilters;
        draft.filters = {
          categories,
          order,
          subcategories: categories.length === 0 ? [] : subcategories,
          marketCategories,
          lowPrice,
          lowStock,
          highPrice,
          highStock,
          lowPriceMarket,
          highPriceMarket,
          counties,
          deliveries,
          units,
          keyword,
        };
      });
    }

    case ActionTypes.SET_MAP_INFO: {
      const newMapInformation = action.payload.mapInformation;
      return produce(state, (draft) => {
        const { mapInformation } = draft;
        const {
          positionString = mapInformation.positionString,
          range = mapInformation.range,
        } = newMapInformation;
        draft.mapInformation = {
          positionString,
          range,
        };
      });
    }

    case ActionTypes.GET_CATEGORIES: {
      const { data } = action.payload;
      return produce(state, (draft) => {
        draft.categories = data;
      });
    }
    case ActionTypes.GET_COUNTIES: {
      const { data } = action.payload;
      return produce(state, (draft) => {
        draft.counties = data;
      });
    }
    case ActionTypes.GET_DELIVERIES: {
      const { data } = action.payload;
      return produce(state, (draft) => {
        draft.deliveries = data;
      });
    }
    case ActionTypes.GET_UNITS: {
      const { data } = action.payload;
      return produce(state, (draft) => {
        draft.units = data;
      });
    }

    default: {
      return state;
    }
  }
};
