import { Action } from 'redux';

export interface State {
  showPopularItems: boolean | undefined;
  showCommunities: boolean | undefined;
  showMarket: boolean | undefined;
}

export enum ActionTypes {
  GET_META = 'store/meta/GET_META',
}

export interface metaData {
  data: {
    id: number;
    attributes: {
      showPopularItems: boolean;
      showCommunities: boolean;
      showMarket: boolean;
      created_at: string;
      updated_at: string;
    };
  };
}

export interface getMetaConfigs extends Action {
  type: ActionTypes.GET_META;
  payload: {
    data: metaData;
  };
}

export type Actions = getMetaConfigs;
