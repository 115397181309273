import { State } from './types';

const initialState: State = {
  mapInformation: {
    positionString: '',
    range: NaN,
  },
  position: {
    latitude: 0,
    longitude: 0,
    km: 100000,
  },
  producers: undefined,
  products: undefined,
  productsByCategory: undefined,
  demands: undefined,
  communityProducts: undefined,
  enhancedFilters: [],
  filters: {
    categories: [],
    subcategories: [],
    marketCategories: [],
    units: [],
    deliveries: [],
    lowPrice: NaN,
    highPrice: NaN,
    lowStock: NaN,
    highStock: NaN,
    lowPriceMarket: NaN,
    highPriceMarket: NaN,
    counties: [],
    keyword: '',
    order: '',
  },
  categories: [],
  labels: [],
  units: [],
  deliveries: [],
  counties: [],
};

export default initialState;
