import { ActionCreator } from 'redux';
import { getMetaConfigs, metaData } from './types';
import { Thunk } from 'store/types';
import { v4 as uuidv4 } from 'uuid';
import { beginActivity, endActivity, setError } from 'store/request';

import { ActionTypes } from './types';

export const getmetaAction: ActionCreator<getMetaConfigs> = (
  data: metaData
) => ({
  type: ActionTypes.GET_META,
  payload: {
    data,
  },
});

export const getMeta = (): Thunk => async (dispatch, getState, context) => {
  const activityId = uuidv4();
  try {
    await dispatch(
      beginActivity({
        type: ActionTypes.GET_META,
        uuid: activityId,
      })
    );
    const meta = await context.api.data.meta().getMeta();

    dispatch(getmetaAction(meta));
  } catch (error) {
    await dispatch(
      setError({
        type: ActionTypes.GET_META,
        error: error.message,
        uuid: uuidv4(),
      })
    );
  } finally {
    await dispatch(endActivity({ uuid: activityId }));
  }
};
