/* Gogo Language Texts

Table of Contents

01.General
02.User Login, Logout, Register
03.Menu
04.Error Page
*/

module.exports = {
  /* 01.General */
  'general.copyright': 'Gogo React © Todos los derechos reservados.',

  /* 02.Inicio de sesión de usuario, cierre de sesión, registro */
  'user.login-title': 'Iniciar sesión',
  'user.register': 'Registro',
  'user.forgot-password': 'Se te olvidó tu contraseña',
  'user.email': 'Email',
  'user.password': 'Contraseña',
  'user.forgot-password-question': '¿Contraseña olvidada?',
  'user.fullname': 'Nombre completo',
  'user.login-button': 'INICIAR SESIÓN',
  'user.register-button': 'REGISTRO',
  'user.reset-password-button': 'REINICIAR',
  'user.buy': 'COMPRAR',
  'user.username': 'Nombre de Usuario',

  /* 03.Menú */
  'menu.app': 'Inicio',
  'menu.dashboards': 'Tableros',
  'menu.home': 'Informații',
  'menu.about-us': 'Despre Noi',
  'menu.presentation': 'Prezentare Conținut',
  'menu.usage': 'Cum funcționează?',
  'menu.packages': 'Pachete disponibile',
  'menu.feedback': 'Feedback',
  'menu.map': 'Hartă',
  'menu.start': 'Comienzo',
  'menu.producers': 'Producatori',
  'menu.cont': 'Cont',
  'menu.fito': 'Fitosanitare',
  'menu.info': 'Detalii Profil',
  'menu.my-products': 'Produsele Mele',
  'menu.my-offers': 'Ofertele Mele',
  'menu.my-address': 'Adresele Mele',
  'menu.invite-users': 'Invită persoane',
  'menu.add-product': 'Adaugă produs',
  'menu.add-offer': 'Adaugă ofertă',
  'menu.second-menu': 'Segundo menú',
  'menu.second': 'Segundo',
  'menu.ui': 'IU',
  'menu.charts': 'Gráficos',
  'menu.chat': 'Chatea',
  'menu.survey': 'Encuesta',
  'menu.todo': 'Notas',
  'menu.search': 'Búsqueda',
  'menu.docs': 'Docs',
  'menu.blank-page': 'Blank Page',
  /* 04.Error  */
  'layouts.error-title': 'Vaya, parece que ha ocurrido un error!',
  'layouts.error-code': 'Código de error',
  'layouts.go-back-home': 'REGRESAR A INICIO',
};
