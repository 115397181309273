import {
  sendDeleteRequest,
  sendGetRequest,
  sendPostRequest,
  sendPutRequest,
} from 'api/utils';
import { AxiosResponse } from 'axios';
import qs from 'qs';
import { Products } from 'store/map';
import { getTTLLocalStorage, setTTLLocalStorage } from 'store/map/helpers';
import { api_url, api_url_strapi } from '../utils/consts';
import {
  Adverts,
  BannerProducers,
  Banners,
  Category,
  Community,
  County,
  DeleteReviewResponse,
  Delivery,
  Demand,
  DemandId,
  DemandLikeInformation,
  Demands,
  Feedback,
  GetAdvertsResponse,
  GetAvailablePackageOfCustomerResponse,
  GetBannersResponse,
  GetCategoryResponse,
  GetCommunitiesResponse,
  GetCommunityResponse,
  GetCountyResponse,
  GetDefaultPromotionalCodeResponse,
  GetDeliveryResponse,
  GetDemandResponse,
  GetEmailResponse,
  GetFeedbackResponse,
  GetHelpersResponse,
  GetLabelResponse,
  GetLastPaymentCustomerResponse,
  GetMinimumPriceResponse,
  GetOffersResponse,
  GetPackageOfCustomerResponse,
  GetPackageTypesResponse,
  GetPaymentsCustomerResponse,
  GetPaymentTypesResponse,
  GetPhonesResponse,
  GetPopularProductsResponse,
  GetProducerResponse,
  GetProducersCarouselResponse,
  GetProducersResponse,
  GetProductResponse,
  GetProductsResponse,
  GetPromotionalCodeResponse,
  GetPromotionResponse,
  GetReviewsResponse,
  GetSingleDemandResponse,
  GetUnitResponse,
  GetUserBenefitsResponse,
  GiveLikeResponse,
  Helpers,
  Label,
  lastProducts,
  Like,
  MapPosition,
  MinimumPrice,
  Offer,
  Package,
  PackageType,
  Payment,
  PaymentType,
  PopularProducts,
  PostPackageResponse,
  PostPaymentResponse,
  PostReviewResponse,
  Producers,
  Product,
  ProductId,
  PromotionalCode,
  PromotionProducers,
  PutPackageResponse,
  PostSearchHistoryResponse,
  PutViewsResponse,
  Review,
  SentPackage,
  SentReview,
  Unit,
  UserBenefits,
  getAvailableProductsFromIdsResponse,
} from './types';

export default () => ({
  getProducers: async (position?: MapPosition): Promise<Producers[]> => {
    const { data }: GetProducersResponse = await sendPostRequest(
      api_url + 'api/v1.0/platform/user',
      position && { ...position }
    );
    return data;
  },
  //
  getProducersCarousel: async (): Promise<BannerProducers[]> => {
    const promotion = getTTLLocalStorage('producer_banner');
    if (promotion) return promotion;
    const { data }: GetProducersCarouselResponse = await sendGetRequest(
      api_url + 'api/v1.0/platform/promotion?slug=producatori-banner'
    );
    setTTLLocalStorage('producer_banner', data, 43200);
    return data;
  },

  getMonthProducer: async (): Promise<BannerProducers[]> => {
    const promotion = getTTLLocalStorage('producer_banner_month');
    if (promotion) return promotion;
    const { data }: GetProducersCarouselResponse = await sendGetRequest(
      api_url + 'api/v1.0/platform/promotion?slug=producatorul-lunii'
    );
    setTTLLocalStorage('producer_banner_month', data, 43200);

    return data;
  },

  getPromotionProducers: async (): Promise<PromotionProducers[]> => {
    const promotion = getTTLLocalStorage('producer_promoted');
    if (promotion) return promotion;
    const { data }: GetPromotionResponse = await sendGetRequest(
      api_url + 'api/v1.0/platform/promotion?slug=producatori-promovati'
    );
    setTTLLocalStorage('producer_promoted', data, 43200);
    return data;
  },

  getPopularProducts: async (): Promise<PopularProducts[]> => {
    const { data }: GetPopularProductsResponse = await sendGetRequest(
      api_url + 'api/v1.0/platform/views/getPopularItems?limit=30'
    );
    const newDataWithoutDeletedItems = data.filter(
      ({ product }) => product[0].isDeleted !== 1
    );

    return newDataWithoutDeletedItems;
  },

  getAvailableProductsFromIds: async (ids: string[]): Promise<string[]> => {
    const {
      data,
    }: getAvailableProductsFromIdsResponse = await sendPostRequest(
      api_url + 'api/v1.0/platform/stock/checkAvailability',
      { ids }
    );
    return data;
  },

  getProducts: async (position?: MapPosition): Promise<Products[]> => {
    const { data }: GetProductsResponse = await sendPostRequest(
      api_url + 'api/v1.0/platform/stock',
      position && { ...position }
    );
    return data;
  },

  getTenProducts: async (
    lastProductsObject: lastProducts
  ): Promise<Products[]> => {
    const { data }: GetProductsResponse = await sendPostRequest(
      api_url + 'api/v1.0/platform/stock',
      lastProductsObject
    );
    return data;
  },
  getUserProducts: async (userId?: Number[]): Promise<Products[]> => {
    const { data }: GetProductsResponse = await sendGetRequest(
      api_url + 'api/v1.0/platform/stock/' + userId
    );
    return data;
  },

  getCategoriesProducts: async (
    categoryIds?: Number[],
    position?: MapPosition
  ): Promise<Products[]> => {
    const { data }: GetProductsResponse = await sendPostRequest(
      api_url + 'api/v1.0/platform/stock',
      { categoryIds, ...(position && { ...position }) }
    );
    return data;
  },

  getDemands: async (token): Promise<Demands[]> => {
    const { data }: GetDemandResponse = await sendGetRequest(
      api_url + 'api/v1.0/platform/demand',
      token
    );
    return data;
  },

  getCommunityProducts: async (code: string): Promise<Products[]> => {
    const { data }: GetProductsResponse = await sendPostRequest(
      api_url + 'api/v1.0/platform/stock',
      { communityCode: code }
    );
    return data;
  },
  getFitoProducts: async (token: string): Promise<Products[]> => {
    const { data }: GetProductsResponse = await sendGetRequest(
      api_url + 'api/v1.0/platform/stock/fito',
      token
    );
    return data;
  },
  getMinimumPrice: async (id: number): Promise<MinimumPrice> => {
    const { data }: GetMinimumPriceResponse = await sendGetRequest(
      api_url + 'api/v1.0/platform/minimumPrice/' + id
    );
    return data;
  },

  postMinimumPrice: async (minPrice: MinimumPrice): Promise<any> => {
    const { data }: any = await sendPostRequest(
      api_url + 'api/v1.0/platform/minimumPrice',
      minPrice
    );
    return data;
  },

  getCategories: async (): Promise<Category[]> => {
    const { data }: GetCategoryResponse = await sendGetRequest(
      api_url + 'api/v1.0/platform/category'
    );
    return data;
  },

  getCounties: async (token): Promise<County[]> => {
    const { data }: GetCountyResponse = await sendGetRequest(
      api_url + 'api/v1.0/platform/county',
      token
    );
    return data;
  },
  getCategoriesFito: async (): Promise<Category[]> => {
    const { data }: GetCategoryResponse = await sendGetRequest(
      api_url + 'api/v1.0/platform/category/fito'
    );
    return data;
  },
  getOffers: async (id: number, token): Promise<Offer[]> => {
    const { data }: GetOffersResponse = await sendGetRequest(
      api_url + 'api/v1.0/platform/offer/?offererId=' + id,
      token
    );
    return data;
  },
  getReviews: async (id: number): Promise<Review[]> => {
    const { data }: GetReviewsResponse = await sendGetRequest(
      api_url + 'api/v1.0/platform/review/?reviewedUserId=' + id
    );
    return data;
  },
  getPackageTypes: async (): Promise<PackageType[]> => {
    const { data }: GetPackageTypesResponse = await sendGetRequest(
      api_url + 'api/v1.0/platform/packageType'
    );
    return data;
  },
  getPaymentTypes: async (): Promise<PaymentType[]> => {
    const { data }: GetPaymentTypesResponse = await sendGetRequest(
      api_url + 'api/v1.0/platform/paymentType'
    );
    return data;
  },
  getPaymentsCustomer: async (token): Promise<Payment[]> => {
    const { data }: GetPaymentsCustomerResponse = await sendGetRequest(
      api_url + 'api/v1.0/platform/payment/customer',
      token
    );
    return data;
  },
  getLastPaymentCustomer: async (token): Promise<Payment> => {
    const { data }: GetLastPaymentCustomerResponse = await sendGetRequest(
      api_url + 'api/v1.0/platform/payment/customer/latest',
      token
    );
    return data;
  },
  postPayment: async (
    id: number,
    paymentTypeId: number,
    token
  ): Promise<Payment> => {
    const { data }: PostPaymentResponse = await sendPostRequest(
      api_url + 'api/v1.0/platform/payment',
      {
        packageId: id,
        paymentTypeId: paymentTypeId,
      },
      token
    );
    return data;
  },
  getPromotionalCode: async (
    promotionalCode: PromotionalCode
  ): Promise<PromotionalCode[]> => {
    const { data }: GetPromotionalCodeResponse = await sendGetRequest(
      api_url +
        `api/v1.0/platform/promotionalCode/?title=${promotionalCode.title}&packageTypeId=${promotionalCode.packageTypeId}`
    );
    return data;
  },
  getDefaultPromotionalCode: async (
    promotionalCode: PromotionalCode
  ): Promise<PromotionalCode[]> => {
    const { data }: GetDefaultPromotionalCodeResponse = await sendGetRequest(
      api_url +
        `api/v1.0/platform/promotionalCode/?isDefault=${promotionalCode.isDefault}`
    );
    return data;
  },
  getCommunitiesPromotionalCode: async (
    promotionalCode: PromotionalCode
  ): Promise<PromotionalCode[]> => {
    const { data }: GetDefaultPromotionalCodeResponse = await sendGetRequest(
      api_url +
        `api/v1.0/platform/promotionalCode/?communityId=${promotionalCode.communityId}`
    );
    return data;
  },
  getPackageOfCustomer: async (token): Promise<Package[]> => {
    const { data }: GetPackageOfCustomerResponse = await sendGetRequest(
      api_url + 'api/v1.0/platform/package/customer',
      token
    );
    return data;
  },
  getAvailablePackageOfCustomer: async (token): Promise<Package[]> => {
    const {
      data,
    }: GetAvailablePackageOfCustomerResponse = await sendGetRequest(
      api_url + 'api/v1.0/platform/package/customer/available',
      token
    );
    return data;
  },
  postPackage: async (pacakgeSent: Package, token): Promise<SentPackage[]> => {
    const { data }: PostPackageResponse = await sendPostRequest(
      api_url + 'api/v1.0/platform/package',
      pacakgeSent,
      token
    );
    return data;
  },
  putPackage: async (
    id: number,
    pacakgeSent: Package,
    token
  ): Promise<SentPackage[]> => {
    const { data }: PutPackageResponse = await sendPutRequest(
      api_url + 'api/v1.0/platform/package/' + id,
      pacakgeSent,
      token
    );
    return data;
  },
  postSearchHistory: async (keyword: string): Promise<any> => {
    const { data }: PostSearchHistoryResponse = await sendPostRequest(
      api_url + 'api/v1.0/platform/searchHistory',
      {
        keyword: keyword,
      }
    );
    return data;
  },
  updateViews: async (id: number): Promise<String> => {
    const { data }: PutViewsResponse = await sendPutRequest(
      api_url + 'api/v1.0/platform/views/updateViews/' + id,
      null
    );
    return data;
  },
  postReview: async (
    token: string,
    review: SentReview
  ): Promise<SentReview[]> => {
    const { data }: PostReviewResponse = await sendPostRequest(
      api_url + 'api/v1.0/platform/review',
      review,
      token
    );
    return data;
  },
  deleteReview: async (token: string, id: number): Promise<{ id: number }> => {
    const { data }: DeleteReviewResponse = await sendDeleteRequest(
      api_url + 'api/v1.0/platform/review/' + id,
      token
    );
    return data;
  },
  getUnits: async (): Promise<Unit[]> => {
    const { data }: GetUnitResponse = await sendGetRequest(
      api_url + 'api/v1.0/platform/measurementunit'
    );
    return data;
  },
  getPhone: async (id: string): Promise<{ phone: string }> => {
    const { data }: GetPhonesResponse = await sendGetRequest(
      api_url + 'api/v1.0/platform/user/phone/' + id
    );
    return data;
  },
  getEmail: async (id: string): Promise<{ email: string }> => {
    const { data }: GetEmailResponse = await sendGetRequest(
      api_url + 'api/v1.0/platform/user/email/' + id
    );
    return data;
  },
  getUserData: async (id: string): Promise<Producers[]> => {
    const { data }: GetProducerResponse = await sendGetRequest(
      api_url + 'api/v1.0/platform/user/' + id
    );
    return data;
  },
  getUserDataAffiliate: async (affiliateCode: string): Promise<Producers[]> => {
    const { data }: GetProducerResponse = await sendGetRequest(
      api_url + 'api/v1.0/platform/user/affiliate/' + affiliateCode
    );
    return data;
  },
  sendFeedback: async (feedback: Feedback): Promise<Feedback> => {
    const { data }: GetFeedbackResponse = await sendPostRequest(
      api_url + 'api/v1.0/platform/user/feedback',
      feedback
    );
    return data;
  },
  getProduct: async (id: ProductId): Promise<Product> => {
    const { data }: GetProductResponse = await sendPostRequest(
      api_url + 'api/v1.0/platform/stock/',
      id
    );
    return data;
  },

  getDemand: async (id: DemandId): Promise<Demand> => {
    const { data }: GetSingleDemandResponse = await sendGetRequest(
      api_url + 'api/v1.0/platform/demand/?id=' + id
    );
    return data;
  },

  getDeliveryType: async (): Promise<Delivery> => {
    const { data }: GetDeliveryResponse = await sendGetRequest(
      api_url + 'api/v1.0/platform/deliveryType'
    );
    return data;
  },
  getCommunities: async (): Promise<Community[]> => {
    const { data }: GetCommunitiesResponse = await sendGetRequest(
      api_url + 'api/v1.0/platform/community'
    );
    return data;
  },
  getCommunity: async (code: string): Promise<Community> => {
    const { data }: GetCommunityResponse = await sendGetRequest(
      api_url + `api/v1.0/platform/community/code/${code}`
    );
    return data;
  },
  getCommunitiesByAuth: async (authCode: string): Promise<Community[]> => {
    const { data }: GetCommunitiesResponse = await sendGetRequest(
      api_url + `api/v1.0/platform/community/auth/${authCode}`
    );
    return data;
  },
  setCommunity: async (id: string): Promise<string> => {
    const token = localStorage.getItem('token');
    const { data }: AxiosResponse<string> = await sendPostRequest(
      api_url + 'api/v1.0/platform/community/' + id,
      null,
      token || ''
    );
    return data;
  },
  giveLike: async (
    token: string,
    likeData: DemandLikeInformation
  ): Promise<Like> => {
    const { data }: GiveLikeResponse = await sendPostRequest(
      api_url + 'api/v1.0/platform/demand/like',
      likeData,
      token
    );
    return data;
  },

  getLabels: async (): Promise<Label[]> => {
    const localLabels = getTTLLocalStorage('labeled_categories');
    if (localLabels) return localLabels;
    const { data }: GetLabelResponse = await sendGetRequest(
      api_url + 'api/v1.0/platform/label'
    );
    setTTLLocalStorage('labeled_categories', data, 43200);

    return data;
  },

  getLabelsByName: async (name: string): Promise<Label[]> => {
    const { data }: GetLabelResponse = await sendGetRequest(
      api_url + 'api/v1.0/platform/label?name=' + name
    );
    return data;
  },

  getAllAdverts: async (): Promise<Adverts> => {
    const { data }: GetAdvertsResponse = await sendGetRequest(
      api_url_strapi + '/adverts?populate=*'
    );
    return data;
  },
  getUserBenefits: async (): Promise<UserBenefits> => {
    const { data }: GetUserBenefitsResponse = await sendGetRequest(
      api_url_strapi + '/user-benefit?populate=*'
    );
    return data;
  },
  getAllAdvertsFiltered: async (forProducer): Promise<Adverts> => {
    const { data }: GetAdvertsResponse = await sendGetRequest(
      api_url_strapi +
        `/adverts?populate=*&filters[forProducer][$eq]=${forProducer}`
    );
    return data;
  },
  getAllHelpers: async (): Promise<Helpers> => {
    const { data }: GetHelpersResponse = await sendGetRequest(
      api_url_strapi + `/helpers?populate=*`
    );
    return data;
  },

  getBannersInformation: async (): Promise<Banners> => {
    const queryArray = [
      'BannerConstructor',
      'BannerConstructor.background',
      'BannerConstructor.background.media',
      'BannerConstructor',
      'BannerConstructor.Logo',
      'BannerConstructor.Logo.media',
    ];

    const query = qs.stringify(
      {
        populate: {
          RecommendationsBanner: {
            populate: queryArray,
          },
          MonthProducer: {
            populate: queryArray,
          },
          MainBanner: {
            populate: queryArray,
          },
          threeWayRendererBanner: {
            populate: queryArray,
          },
        },
      },
      {
        encodeValuesOnly: true,
      }
    );

    const { data }: GetBannersResponse = await sendGetRequest(
      api_url_strapi + `/banner?${query}`
    );

    return data;
  },
});
