import produce from 'immer';
import { ActionTypes } from '.';
import initialState from './initialState';
import { State, Actions } from './types';

export default (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    // case ActionTypes.GET_PARTNERS: {
    //   const { data } = action.payload;

    //   return produce(state, (draft) => {
    //     const allPartners = data.allPartners.map((item) => {
    //       return {
    //         url: item.url,
    //         id: item.id,
    //         image: {
    //           url: item.image.url,
    //           id: item.image.id,
    //           name: item.image.name,
    //         },
    //       };
    //     });
    //     draft.partners = { title: data.title, allPartners: allPartners };
    //   });
    // }
    case ActionTypes.GET_TERMS_CONDITIONS: {
      const {
        data: { data },
      } = action.payload;

      return produce(state, (draft) => {
        draft.termsAndConditions = {
          title: data.attributes.title,
          description: data.attributes.description,
        };
      });
    }
    case ActionTypes.GET_PAGES: {
      const {
        data: { data },
      } = action.payload;

      return produce(state, (draft) => {
        const pages = data
          .map((item) => ({
            id: item.id,
            title: item.attributes.title,
            description: item.attributes.description,
            slug: item.attributes.slug,
            partners: item.attributes.partners,
            icon: item.attributes.icon,
            order: item.attributes.NumberOrder,
          }))
          .sort((a, b) => a.order - b.order);

        draft.pages = pages;
      });
    }
    default: {
      return state;
    }
  }
};
