import { createSelector } from 'reselect';
import { State } from './types';

// export const selectAllPartners = createSelector(
//   (state: State) => {
//     const { partners } = state;
//     return partners;
//   },
//   (partners) => partners
// );

// export const selectPartnersAreFetched = createSelector(
//   (state: State) => {
//     const { partners } = state;

//     return partners.title === '' ? false : true;
//   },
//   (fetched) => fetched
// );

export const selectTemrsAndConditions = createSelector(
  (state: State) => {
    const { termsAndConditions } = state;
    return termsAndConditions;
  },
  (termsAndConditions) => termsAndConditions
);

export const selectTermsAndCondAreFetched = createSelector(
  (state: State) => {
    const { termsAndConditions } = state;

    return termsAndConditions.title === '' ? false : true;
  },
  (fetched) => fetched
);

export const selectAllPages = createSelector(
  (state: State) => {
    const { pages } = state;
    return pages;
  },
  (pages) => pages
);

export const selectPagesAreFetched = createSelector(
  (state: State) => {
    const { pages } = state;
    return pages.length === 0 ? false : true;
  },
  (pages) => pages
);
